import { DataGrid } from '@mui/x-data-grid';
import { Card, CardHeader, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from '@apollo/client';
import GET_HUBS from './hubQuery';
import Loading from '../../shared/Loading';

export default function DisplayHub() {
  const { loading, error, data } = useQuery(GET_HUBS);
  if (loading) return <Loading />;
  if (error) return 'Error!';

  const columns = [
    { field: 'hubName', headerName: 'Hub Name', width: 250 },
    {
      field: 'mobileNo',
      headerName: 'Mobile',
      type: 'Number',
      width: 150
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150
    },
    {
      field: 'address',
      headerName: 'Address',
      sortable: false,
      width: 400
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              // eslint-disable-next-line no-alert
              alert(params.id);
            }}
            startIcon={<EditIcon />}
          >
            EDIT
          </Button>
        </strong>
      )
    }
  ];
  const rows = data.hubs;

  return (
    <Card>
      <CardHeader subheader="Currently added Hubs." title="Hubs" />
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} />
      </div>
    </Card>
  );
}
