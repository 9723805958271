import { gql } from '@apollo/client';

const GET_ORDER = gql`
  query GetOrder($id: ID!) {
    order(id: $id) {
      customer {
        firstName
        lastName
        phone
        address
      }
      items
      deliveryDate
      status
      comment
    }
  }
`;

export default GET_ORDER;
