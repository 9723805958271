import { gql } from '@apollo/client';

const ADD_ADDRESS = gql`
  mutation AddAddress($name: String!, $customerID: String!) {
    addAddress(name: $name, customerID: $customerID) {
      id
      name
    }
  }
`;

const EDIT_ADDRESS = gql`
  mutation EditAddress($name: String, $id: ID!) {
    editAddress(name: $name, id: $id) {
      id
      name
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: ID!) {
    deleteAddress(id: $id) {
      id
      name
    }
  }
`;

export { DELETE_ADDRESS, ADD_ADDRESS, EDIT_ADDRESS };
