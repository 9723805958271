import { NavLink as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardHeader, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from '@apollo/client';
import GET_LOCATIONS from './locationQuery';
import Loading from '../../shared/Loading';

export default function DisplayRegion() {
  const { loading, error, data } = useQuery(GET_LOCATIONS);
  if (loading) return <Loading />;
  if (error) return 'Error!';

  const columns = [
    {
      field: 'name',
      headerName: 'Location Name',
      width: 300
    },
    {
      field: 'routeName',
      headerName: 'Route',
      width: 150,
      valueGetter: (params) => params.row.route.routeName
    },
    {
      field: 'regionName',
      headerName: 'Region Name',
      width: 300,
      valueGetter: (params) => params.row.region.name
    },
    {
      field: 'hubName',
      headerName: 'Hub Name',
      width: 200,
      valueGetter: (params) => params.row.region.hub.hubName
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Button
            component={RouterLink}
            // eslint-disable-next-line react/destructuring-assignment
            to={params.id}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<EditIcon />}
          >
            EDIT
          </Button>
        </strong>
      )
    }
  ];
  const rows = data.locations;

  return (
    <Card>
      <CardHeader subheader="Currently added Locations." title="Locations" />
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={10} />
      </div>
    </Card>
  );
}
