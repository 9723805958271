import { gql } from '@apollo/client';

const EDIT_LOCATION = gql`
  mutation EditLocation(
    $id: ID!
    $name: String
    $regionID: String
    $routeID: String
  ) {
    editLocation(id: $id, name: $name, regionID: $regionID, routeID: $routeID) {
      id
      name
      route {
        routeName
      }
      region {
        name
        hub {
          hubName
        }
      }
    }
  }
`;

export default EDIT_LOCATION;
