import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';

import AddCustomer from '../components/customer/customer/AddCustomer';
import DisplayCustomer from '../components/customer/customer/DisplayCustomer';

const Customer = () => (
  <>
    <Helmet>
      <title>Customers | Milkton</title>
    </Helmet>
    <AddCustomer />
    <Box p={3} />
    <DisplayCustomer />
  </>
);

export default Customer;
