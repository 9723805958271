import { useParams, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardHeader, Box, Typography, Button } from '@mui/material';
import date from 'date-and-time';
import { useQuery, useMutation } from '@apollo/client';
import axios from 'axios';
import {
  GET_DELIVERED_ORDERS_BY_CUSTOMER_ID_AND_DATE,
  GET_CREDIT_TRANSACTION_BY_CUSTOMER_ID_AND_DATE
} from './billQuery';
import GET_CUSTOMER_BY_ID from '../../customer/customerDetail/customerDetailQuery';
import ADD_TRANSACTION from '../../customer/transaction/transactionMutation';
import Loading from '../../shared/Loading';
import { kPayumoneyPaymentUrl } from '../../../utils/apiConstants';

export default function DisplayBills() {
  const navigate = useNavigate();
  function getRow(rows) {
    const itemisedRow = [];
    let count = 0;
    rows.forEach((row) => {
      row.items.forEach((item) => {
        const newOrder = {
          id: count,
          productID: item.productID,
          quantity: item.quantity,
          deliveryDate: row.deliveryDate,
          price: item.price,
          name: item.name
        };
        itemisedRow.push(newOrder);
        count++;
      });
    });
    return itemisedRow;
  }
  const { customerID, mm, yyyy } = useParams();
  function getTotalDebit(rows) {
    let totalDebit = 0;
    rows.forEach((row) => {
        totalDebit += row.price * row.quantity
    });
    return totalDebit;
  }
  function getTotalCredit(rows) {
    let totalCredit = 0;
    rows.forEach((row) => {
      totalCredit += row.subTotal;
    });
    return totalCredit;
  }
  const lastDay = new Date(+yyyy, +mm, 0);
  const customerData = useQuery(GET_CUSTOMER_BY_ID, {
    variables: { id: customerID }
  });
  const [addTransaction, addedTransactionData] = useMutation(ADD_TRANSACTION);
  const { loading, error, data } = useQuery(
    GET_DELIVERED_ORDERS_BY_CUSTOMER_ID_AND_DATE,
    {
      variables: {
        customerID,
        startDate: `${yyyy}-${mm}-01`,
        endDate: date.format(lastDay, 'YYYY-MM-DD')
      }
    }
  );
  const creditData = useQuery(GET_CREDIT_TRANSACTION_BY_CUSTOMER_ID_AND_DATE, {
    variables: {
      customerID,
      startDate: `${yyyy}-${mm}-01`,
      endDate: date.format(lastDay, 'YYYY-MM-DD')
    }
  });

  if (loading) return <Loading />;
  if (error) return 'Something went wrong, Please try again later.';
  if (customerData.loading) return <Loading />;
  if (customerData.error) return `${customerData.error}`;
  if (addedTransactionData.loading) return <Loading />;
  if (addedTransactionData.error) return `${addedTransactionData.error}`;
  if (creditData.loading) return <Loading />;
  if (creditData.error) return `${creditData.error}`;

  const creditColumn = [
    {
      field: 'comment',
      headerName: 'Comment',
      width: 400,
      sortable: false
    },
    {
      field: 'subTotal',
      headerName: 'Amount',
      width: 110,
      sortable: false
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      sortable: false,
      valueGetter: (params) => new Date(params.row.date).toDateString()
    }
  ];

  const columns = [
    {
      field: 'product',
      headerName: 'Purchase',
      width: 300,
      sortable: false,
      valueGetter: (params) => {
        if (params.row.name) {
          return `${params.row.name}`;
        }
        return 'Not found';
      }
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      width: 110,
      sortable: false
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 110,
      sortable: false,
      // eslint-disable-next-line no-confusing-arrow
      valueGetter: (params) => {
        if (params.row.price) {
          return `${params.row.price * params.row.quantity}`;
        }
        return 'Not found';
      }
    },
    {
      field: 'deliveryDate',
      headerName: 'Date',
      width: 150,
      sortable: false,
      valueGetter: (params) => new Date(params.row.deliveryDate).toDateString()
    }
  ];

  const dateRange = `Settlement Cycle: ${new Date(
    `${yyyy}-${mm}-01`
  ).toDateString()} to ${lastDay.toDateString()}`;
  const rows = getRow(data.deliveredOrdersByCustomerIDAndDate);
  const creditRow = creditData.data.creditTransactionsByCustomerIDAndDate;
  const customerName = `Dear, ${customerData.data.customer.firstName} ${customerData.data.customer.lastName}`;
  const customerPhone = `Mobile: +91-${customerData.data.customer.phone}`;
  // const customerBalance = `Wallet Balance: ₹ ${customerData.data.customer.wallet}`;
  const customerBalance = '';
  const customerHub = `Hub: ${customerData.data.customer.location.region.hub.hubName}`;
  const currentBillingCycle = `b${mm}`;
  const billAmount =
    customerData.data.customer[currentBillingCycle] > 0
      ? `Amount to be Paid: ₹${customerData.data.customer[currentBillingCycle]}`
      : 'Amount to be Paid: 0 (No Dues)';
  const totalDebit = `Total Debit: ₹${getTotalDebit(rows)}`;
  const totalCredit = `Total Credit: ₹${getTotalCredit(creditRow)}`;

  return (
    <>
      <Box
        p={3}
        sx={{
          backgroundColor: 'background.default'
        }}
      >
        <Card>
          <CardHeader />
          <Box
            style={{
              marginLeft: 50,
              marginRight: 50,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h4">{customerName}</Typography>
            <Typography variant="h4">{customerPhone}</Typography>
            <Typography variant="h4">{customerHub}</Typography>
          </Box>
          <Box p={3} />
          <Box
            style={{
              marginLeft: 50,
              marginRight: 50,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h4">{customerBalance}</Typography>
            <Typography variant="h4">{billAmount}</Typography>
            <Button
              color="secondary"
              variant="contained"
              disabled={customerData.data.customer[currentBillingCycle] <= 0}
              onClick={() => {
                const pd = {
                  key: process.env.REACT_APP_PAYUMONEY_MERCHANT_KEY,
                  txnid:
                    customerID + mm + yyyy + Math.floor(Math.random() * 1000),
                  amount: customerData.data.customer[currentBillingCycle],
                  firstname: customerData.data.customer.firstName,
                  email: customerData.data.customer.email
                    ? customerData.data.customer.email
                    : 'bill@shreesurbhi.com',
                  phone: customerData.data.customer.phone,
                  productinfo: `Milkton Recharge ${mm}-${yyyy}`,
                  surl: '/bill/success',
                  furl: '/bill/fail',
                  hash: ''
                };
                // Data to be Sent to API to generate hash.
                const apiData = {
                  txnid: pd.txnid,
                  email: pd.email,
                  amount: pd.amount,
                  productinfo: pd.productinfo,
                  firstname: pd.firstname
                };
                axios({
                  method: 'POST',
                  data: apiData,
                  withcredentials: true,
                  url: kPayumoneyPaymentUrl
                })
                  .then((a) => a)
                  .then((json) => {
                    pd.hash = json.data.hash;
                    window.bolt.launch(pd, {
                      responseHandler: (response) => {
                        if (response.response.txnStatus === 'SUCCESS') {
                          addTransaction({
                            variables: {
                              customerID,
                              date: new Date(),
                              isDebit: false,
                              subTotal: parseInt(response.response.amount, 10),
                              comment: `Paid via PayuMoney: ${response.response.paymentId}`,
                              orgID: 'shreesurbhi'
                            }
                          });
                          navigate('/bill/success');
                        } else {
                          navigate('/bill/fail');
                        }
                      },
                      catchException: () => {
                        navigate('/bill/fail');
                      }
                    });
                  });
              }}
            >
              Pay Now
            </Button>
          </Box>
          <Box p={3} />
        </Card>
      </Box>
      <Box
        p={3}
        sx={{
          backgroundColor: 'background.default'
        }}
      >
        <Card>
          <CardHeader />
          <Box
            style={{
              marginLeft: 50,
              marginRight: 50,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h4">{totalCredit}</Typography>
            <Typography variant="h4">{totalDebit}</Typography>
          </Box>
          <Box p={3} />
        </Card>
      </Box>
      <Box p={3}>
        <Card>
          <CardHeader subheader={dateRange} title="Credit Summary" />
          <div style={{ width: '100%' }}>
            <DataGrid
              rows={creditRow}
              columns={creditColumn}
              pageSize={100}
              autoHeight
            />
          </div>
        </Card>
      </Box>
      <Box p={3}>
        <Card>
          <CardHeader subheader={dateRange} title="Purchase Summary" />
          <div style={{ width: '100%' }}>
            <DataGrid rows={rows} columns={columns} pageSize={100} autoHeight />
          </div>
        </Card>
      </Box>
    </>
  );
}
