import { gql } from '@apollo/client';

const SEARCH_CUSTOMER = gql`
  query SearchCustomers($search: String!) {
    searchCustomer(search: $search) {
      id
      firstName
      lastName
      phone
      wallet
      b01
      b02
      b03
      b04
      b05
      b06
      b07
      b08
      b09
      b10
      b11
      b12
      address
      location {
        name
        region {
          hub {
            hubName
          }
        }
        route {
          id
          executive {
            firstName
            phone
          }
        }
      }
    }
  }
`;

export default SEARCH_CUSTOMER;
