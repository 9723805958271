/* eslint-disable react/jsx-one-expression-per-line */
import { Box, Typography, Divider } from '@mui/material';

export default function Footer() {
  return (
    <>
      <Divider />
      <Box p={3}>
        <Typography variant="body2" align="center">
          <a href="https://gepton.com">
            Copyright &#169; 2020-
            {new Date().getFullYear()}. Milkton v2.0.0 | Proudly powered by{' '}
            GEPTON
          </a>
        </Typography>
      </Box>
    </>
  );
}
