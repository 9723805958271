import { gql } from '@apollo/client';

const GET_SUBSCRIPTIONS_BY_CUSTOMER_ID = gql`
  query SubscriptionsByCustomerID($customerID: String!) {
    subscriptionsByCustomerID(customerID: $customerID) {
      id
      customer {
        firstName
        lastName
        phone
        address
      }
      items
      nextDeliveryDate
      endDate
      status
      frequency
    }
  }
`;

export default GET_SUBSCRIPTIONS_BY_CUSTOMER_ID;
