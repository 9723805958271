import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from 'src/services/api';
import { kOrgDetails } from 'src/utils/apiConstants';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Footer from './shared/Footer';
import SuspendedBanner from './account/SuspendedBanner';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = ({ signOutUser }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [validity, setValidity] = useState(new Date());

  useEffect(() => {
    api({
      method: 'GET',
      url: kOrgDetails
    })
      .then((response) => {
        setValidity(response.data.validity);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        signOutUser={signOutUser}
        onMobileNavOpen={() => setMobileNavOpen(true)}
      />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            {new Date(validity) > new Date() ? (
              <Outlet />
            ) : (
              <SuspendedBanner validity={validity} />
            )}
            <Footer />
            <ToastContainer />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
