import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';

const user = {
  avatar: '/static/images/wrong.png',
  city: 'This account has been suspended at:',
  country: '',
  jobTitle: '',
  name: 'Service Suspended',
  timezone: 'due to Non Payment'
};

const SuspendedBanner = ({ validity }) => (
  <Card>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 100,
            width: 100
          }}
        />
        <Typography color="textPrimary" gutterBottom variant="h3">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {`${user.city} ${user.country}`}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {`${new Date(validity).toLocaleString()} ${user.timezone}`}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Button color="primary" fullWidth variant="text" href="tel:9099199105">
        Contact Support: 90 991 991 05
      </Button>
    </CardActions>
  </Card>
);

export default SuspendedBanner;
