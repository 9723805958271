import { Helmet } from 'react-helmet';

import DisplayOrder from '../components/order/order/DisplayOrder';

const Order = () => (
  <>
    <Helmet>
      <title>Orders | Milkton</title>
    </Helmet>
    <DisplayOrder />
  </>
);

export default Order;
