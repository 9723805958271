import { Helmet } from 'react-helmet';
import { NavLink as RouterLink } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Button, Container, Divider, TextField } from '@mui/material';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@apollo/client';
import SEARCH_CUSTOMER from 'src/components/customer/customer/searchCustomerQuery';

const Dashboard = () => {
  const [query, setQuery] = useState('');
  const [search, setSearch] = useState('gepton');

  const { data, loading, error } = useQuery(SEARCH_CUSTOMER, {
    variables: {
      search
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query) setSearch(query);
  };

  const month = `0${new Date().getMonth()}`.slice(-2);
  const billMonth = month !== '00' ? `b${month}` : 'b12';

  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 150 },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 150
    },
    {
      field: 'phone',
      headerName: 'Mobile',

      width: 150
    },
    {
      field: 'wallet',
      headerName: 'Wallet',
      type: 'Number',
      width: 100
    },
    {
      field: billMonth,
      headerName: `${billMonth}`,
      type: 'Number',
      width: 100
    },
    {
      field: 'executive',
      headerName: 'Executive Assigned',
      sortable: false,
      width: 200,
      valueGetter: (params) => {
        let executiveAssigned = '';
        if (params.row.location.route.executive[0] != null) {
          params.row.location.route.executive.forEach((executive) => {
            executiveAssigned += `${executive.firstName} (${executive.phone}), `;
          });
          return executiveAssigned.slice(0, -2);
        }
        return 'Not Assigned';
      }
    },
    {
      field: 'hub',
      headerName: 'Hub Assigned',
      width: 150,
      valueGetter: (params) => params.row.location.region.hub.hubName
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 150,
      valueGetter: (params) => params.row.location.name
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 350
    },
    {
      field: 'view',
      headerName: 'More',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Button
            component={RouterLink}
            variant="contained"
            color="primary"
            size="small"
            // eslint-disable-next-line react/destructuring-assignment
            to={`../customers/${params.id}`}
            startIcon={<LaunchIcon />}
          >
            VIEW
          </Button>
        </strong>
      )
    }
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error.toString()}</div>;

  const rows = data.searchCustomer || [];
  console.log(rows);

  return (
    <>
      <Helmet>
        <title>Dashboard | Milkton</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100vh'
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ margin: '3rem 0rem' }}
        >
          <Container maxWidth="sm">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '10vh'
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                helperText="You can search a customer by name or phone number."
                label="Search Customer..."
                name="query"
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                required
                placeholder="Search Customer..."
              />
              <Box mt={2}>
                <Button variant="contained" color="primary" type="submit">
                  Search
                </Button>
              </Box>
            </Box>
          </Container>
        </form>
        <Divider />
        <Box sx={{ width: '100%', px: 3 }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} autoHeight />
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
