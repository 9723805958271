import { gql } from '@apollo/client';

const GET_CREDIT_TRANSACTION_BY_CUSTOMER_ID_AND_DATE = gql`
  query CreditTransactionsByCustomerIDAndDate(
    $customerID: String
    $startDate: Date
    $endDate: Date
  ) {
    creditTransactionsByCustomerIDAndDate(
      customerID: $customerID
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      subTotal
      comment
      date
    }
  }
`;

const GET_DELIVERED_ORDERS_BY_CUSTOMER_ID_AND_DATE = gql`
  query DeliveredOrdersByCustomerIDAndDate(
    $customerID: String
    $startDate: Date
    $endDate: Date
  ) {
    deliveredOrdersByCustomerIDAndDate(
      customerID: $customerID
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      items
      deliveryDate
    }
  }
`;

export {
  GET_CREDIT_TRANSACTION_BY_CUSTOMER_ID_AND_DATE,
  GET_DELIVERED_ORDERS_BY_CUSTOMER_ID_AND_DATE
};
