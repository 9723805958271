import {
  Card,
  CardHeader,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Box
} from '@mui/material';
import { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'react-toastify';
import Download from 'src/utils/download';
import { useQuery } from '@apollo/client';
import api from 'src/services/api';
import { kCustomerSheetExport } from '../../../utils/apiConstants';
import GET_EXECUTIVES from '../../executive/executive/executiveQuery';

export default function CustomerSheetExport() {
  const { loading, error, data } = useQuery(GET_EXECUTIVES);
  const [executive, setexecutive] = useState('');

  const handleSubmit = () => {
    const payload = {};
    let fileName = '';
    if (!executive || executive === '') {
      fileName = 'customers.csv';
    } else {
      payload.executiveID = executive.id;
      fileName = `c_${executive.firstName}_${executive.lastName}.csv`;
    }
    api({
      method: 'post',
      url: kCustomerSheetExport,
      data: payload
    })
      .then(async (res) => {
        if (res.data.status !== 'Failed') {
          Download({
            name: fileName,
            data: res.data
          });
          toast.success('Download Started');
        } else {
          toast.error('Something went wrong');
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <Card>
      <CardHeader
        subheader="Export Customer sheet in csv based on executives. If no executive is selected, all customers will be exported."
        title="Export Customer"
      />
      <Box
        sx={{
          m: 3
        }}
      >
        <Autocomplete
          id="executive-select"
          options={data.executives}
          onChange={(_, selectedValue) => {
            setexecutive(selectedValue || null);
          }}
          getOptionLabel={(e) => {
            let executiveOption = '';
            executiveOption += `${e.firstName} `;
            executiveOption += `${e.lastName} `;
            executiveOption += `(${e.phone})`;
            return executiveOption;
          }}
          renderInput={(params) => (
            <TextField {...params} label="Executive" variant="outlined" />
          )}
        />
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        paddingBottom={2}
      >
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={handleSubmit}
        >
          Download CSV
        </Button>
      </Grid>
    </Card>
  );
}
