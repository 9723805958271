import { gql } from '@apollo/client';

const ADD_REGION = gql`
  mutation AddRegion($name: String!, $hubID: String!) {
    addRegion(name: $name, hubID: $hubID) {
      id
      name
      hub {
        hubName
      }
    }
  }
`;

const DELETE_REGION = gql`
  mutation DeleteRegion($id: ID!) {
    deleteRegion(id: $id) {
      id
      name
    }
  }
`;

export { ADD_REGION, DELETE_REGION };
