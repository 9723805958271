import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuth, getIdToken } from 'firebase/auth';
import { kGraphqlServerUrl } from '../utils/apiConstants';

const httpLink = createHttpLink({
  uri: kGraphqlServerUrl
});

const authLink = setContext(async (_, { headers }) => {
  const auth = getAuth();
  const token = auth.currentUser ? await getIdToken(auth.currentUser) : '';
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
