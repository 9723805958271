import { kTextLocalSend } from 'src/utils/apiConstants';
import api from '../api';

const textLocalSingleSms = (phones, msg) => {
  api({
    method: 'post',
    withCredentials: true,
    url: kTextLocalSend,
    data: {
      phones,
      msg
    }
  })
    .then((response) => {
      // eslint-disable-next-line no-alert
      alert(`${response.data.status}`);
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      alert('Error');
    });
};

export default textLocalSingleSms;
