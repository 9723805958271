/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardHeader } from '@mui/material';
import { useQuery } from '@apollo/client';
import GET_ORDERS_BY_TODAY from './orderSummaryTodayQuery';
import { GET_PRODUCTS } from '../../product/product/productQuery';
import Loading from '../../shared/Loading';

export default function OrderSummaryForToday() {
  const { loading, error, data } = useQuery(GET_ORDERS_BY_TODAY);
  const products = useQuery(GET_PRODUCTS);

  if (loading) return <Loading />;
  if (error) return 'Error!';
  if (products.loading) return <Loading />;
  if (products.error) return `${products.error}`;
  const productData = products.data.products;

  const columns = [
    {
      field: 'productID',
      headerName: 'Product',
      width: 600,
      valueGetter: (params) => {
        const requiredPrduct = productData.filter(
          (foundProduct) => params.row.productID === foundProduct.id
        );
        return requiredPrduct[0].name;
      }
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      width: 200
    },
    {
      field: 'hubName',
      headerName: 'Hub Name',
      width: 250
    }
  ];
  const totalOrders = data.ordersForToday.filter(
    (o) => o.status !== 'UNDELIVERED'
  );
  const getRow = () => {
    let tempID = 0;
    const tempOrder = [];
    for (let i = 0; i < totalOrders.length; i++) {
      for (let j = 0; j < totalOrders[i].items.length; j++) {
        const currentOrder = {
          id: tempID,
          productID: totalOrders[i].items[j].productID,
          quantity: totalOrders[i].items[j].quantity,
          hubName: totalOrders[i].customer.location.region.hub.hubName
        };
        const existingTempOrder = tempOrder.filter(
          (foundTempOrder) =>
            foundTempOrder.productID === currentOrder.productID &&
            foundTempOrder.hubName === currentOrder.hubName
        );
        if (existingTempOrder.length > 0) {
          existingTempOrder[0].quantity += currentOrder.quantity;
        } else {
          tempOrder.push(currentOrder);
          tempID++;
        }
      }
    }
    return tempOrder;
  };

  const rows = getRow();

  return (
    <Card>
      <CardHeader subheader="For Today" title="Order Summary" />
      <div style={{ width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={10} autoHeight />
      </div>
    </Card>
  );
}
