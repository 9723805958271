import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';

import EditLocation from '../components/location/location/editLocation/EditLocation';

const EditLocationPage = () => (
  <>
    <Helmet>
      <title>Edit Location | Milkton</title>
    </Helmet>
    <Box p={3}>
      <EditLocation />
    </Box>
  </>
);

export default EditLocationPage;
