import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, List, Typography } from '@mui/material';
import {
  BarChart as BarChartIcon,
  // Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  ShoppingCart as OrderIcon,
  Repeat as SubscriptionIcon,
  User as UserIcon,
  // UserPlus as UserPlusIcon,
  DollarSign as TransactionIcon,
  Users as UsersIcon,
  MapPin as LocationIcon,
  // Send as BillingIcon,
  Download as DownloadIcon,
  TrendingUp as ReportsIcon
} from 'react-feather';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/avatars/xori.jpg'
};

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers'
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Products'
  },
  {
    href: '/app/orders',
    icon: OrderIcon,
    title: 'Orders'
  },
  {
    href: '/app/subscriptions',
    icon: SubscriptionIcon,
    title: 'Subscriptions'
  },
  {
    href: '/app/location',
    icon: LocationIcon,
    title: 'Location'
  },
  {
    href: '/app/executive',
    icon: UserIcon,
    title: 'Executives'
  },
  {
    href: '/app/transactions',
    icon: TransactionIcon,
    title: 'Transactions'
  },
  {
    href: '/app/reports',
    icon: ReportsIcon,
    title: 'Reports'
  },
  {
    href: '/app/exports',
    icon: DownloadIcon,
    title: 'Exports'
  }
  // {
  //   href: '/app/account',
  //   icon: BillingIcon,
  //   title: 'Billing'
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/app/register',
  //   icon: UserPlusIcon,
  //   title: 'Add Admin'
  // }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          Shree Surbhi
        </Typography>
        <Typography color="textSecondary" variant="body2">
          support@shreesurbhi.com
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 256
          }
        }}
        sx={{ display: { lg: 'none', xs: 'block' } }}
      >
        {content}
      </Drawer>

      <Drawer
        anchor="left"
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: 256,
            top: 64,
            height: 'calc(100% - 64px)'
          }
        }}
        sx={{ display: { xs: 'none', lg: 'block' } }}
      >
        {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
