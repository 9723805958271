import { gql } from '@apollo/client';

const GET_REGIONS = gql`
  {
    regions {
      id
      name
      hub {
        hubName
      }
      locations {
        id
        name
      }
    }
  }
`;

export default GET_REGIONS;
