import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid';
import { useState } from 'react';
import { Card, CardHeader, Grid, Box, TextField } from '@mui/material';
import date from 'date-and-time';
import { useQuery } from '@apollo/client';
import GET_CREDIT_TRANSACTION_DATE from './transactionQuery';
import Loading from '../shared/Loading';

function ExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function DisplayCreditTransaction() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const initialValue = {
    startDate: date.format(firstDay, 'YYYY-MM-DD'),
    endDate: date.format(lastDay, 'YYYY-MM-DD')
  };
  const [values, setValues] = useState(initialValue);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const { loading, error, data } = useQuery(GET_CREDIT_TRANSACTION_DATE, {
    variables: {
      startDate: values.startDate,
      endDate: values.endDate
    }
  });
  if (loading) return <Loading />;
  if (error) return `${error}`;

  const columns = [
    {
      field: 'name',
      headerName: 'Customer Name',
      width: 200,
      valueGetter: (params) =>
        `${params.row.customer.firstName} ${params.row.customer.lastName}`
    },
    {
      field: 'phone',
      headerName: 'Mobile Number',
      width: 200,
      valueGetter: (params) => params.row.customer.phone
    },
    {
      field: 'subTotal',
      headerName: 'Amount',
      width: 150
    },
    {
      field: 'date',
      headerName: 'Date & Time',
      width: 300,
      valueGetter: (params) => new Date(params.row.date).toLocaleString()
    },
    {
      field: 'isDebit',
      headerName: 'Type',
      width: 120,
      valueGetter: (params) => {
        if (params.row.isDebit) {
          return 'DEBIT';
        }
        return 'CREDIT';
      }
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 300
    }
    // {
    //   field: 'orderID',
    //   headerName: 'Order ID',
    //   width: 300
    // }
  ];
  const rows = data.creditTransactionsByDate;

  return (
    <Card>
      <CardHeader
        subheader="These date selectors may have some timezone mismatch issue. Please select a date prior for best accuracy. Will be fixed really soon ;)"
        title="Transactions"
      />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <TextField
              fullWidth
              helperText="Start Date*"
              name="startDate"
              onChange={handleChange}
              required
              type="date"
              value={values.startDate}
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <TextField
              fullWidth
              helperText="End Date*"
              name="endDate"
              onChange={handleChange}
              required
              type="date"
              value={values.endDate}
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>
      <div style={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={25}
          autoHeight
          components={{
            Toolbar: ExportButton
          }}
        />
      </div>
    </Card>
  );
}
