import {
  Box,
  Typography
} from '@mui/material';

export default function Branding() {
  return (
    <Box
      p={3}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <img
        alt="Jadkhor Logo"
        src="https://jadkhor.org/wp-content/uploads/2018/07/jadkhor-logo.png"
        style={{
          marginLeft: 50,
          display: 'inline-block',
          maxWidth: '100%',
          width: 100
        }}
      />
      <Box
        style={{
          marginRight: 50,
          display: 'inline-block'
        }}
      >
        <Typography variant="h5">Contact: +91-9389891046</Typography>
        <Typography variant="h5">Email: support@shreesurbhi.com</Typography>
      </Box>
    </Box>
  );
}
