import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@mui/material';

const PaymentSuccess = () => (
  <>
    <Helmet>
      <title>Payment Successful | Milkton</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h1">
          🦸‍♂️: Hurray you are a Hero!
        </Typography>
        <Box p={1} />
        <Typography align="center" color="textPrimary" variant="subtitle2">
          Thank you for you payment. Your transaction was Successful.
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <img
            alt="Under development"
            src="/static/images/correct.png"
            style={{
              marginTop: 50,
              display: 'inline-block',
              maxWidth: '100%',
              width: 560
            }}
          />
        </Box>
      </Container>
    </Box>
  </>
);

export default PaymentSuccess;
