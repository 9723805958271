import { gql } from '@apollo/client';

const EDIT_ORDER = gql`
  mutation EDIT_ORDER(
    $id: ID!
    $status: String
    $deliveryDate: DateTime
    $comment: String
    $items: [Item]
  ) {
    markOrder(
      id: $id
      status: $status
      deliveryDate: $deliveryDate
      comment: $comment
      items: $items
    ) {
      customer {
        firstName
        lastName
        phone
        address
      }
      items
      deliveryDate
      status
      comment
    }
  }
`;

export default EDIT_ORDER;
