import { gql } from '@apollo/client';

const GET_PAGINATED_CUSTOMERS = gql`
  query PaginatedCustomer($limit: Int!, $page: Int!) {
    paginatedCustomer(limit: $limit, page: $page) {
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
      docs {
        id
        firstName
        lastName
        phone
        wallet
        b01
        b02
        b03
        b04
        b05
        b06
        b07
        b08
        b09
        b10
        b11
        b12
        address
        location {
          name
          region {
            hub {
              hubName
            }
          }
          route {
            id
            executive {
              firstName
              phone
            }
          }
        }
      }
    }
  }
`;

export default GET_PAGINATED_CUSTOMERS;
