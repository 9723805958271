import { gql } from '@apollo/client';

const GET_ADDRESS_BY_CUSTOMER_ID = gql`
  query GetAddressByCustomerID($customerID: ID!) {
    addressByCustomerID(customerID: $customerID) {
      id
      name
    }
  }
`;

const GET_ADDRESS_BY_ID = gql`
  query GetAddressByID($id: ID!) {
    address(id: $id) {
      name
    }
  }
`;

export { GET_ADDRESS_BY_CUSTOMER_ID, GET_ADDRESS_BY_ID };
