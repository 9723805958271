import { gql } from '@apollo/client';

const MARK_ORDER = gql`
  mutation MARK_ORDER($id: ID!, $status: String) {
    markOrder(id: $id, status: $status) {
      id
      isSub
      items
      status
      deliveryDate
      customer {
        firstName
        lastName
        phone
        address
      }
      comment
    }
  }
`;

const EDIT_EXECUTIVE = gql`
  mutation EditExecutive(
    $id: String!
    $firstName: String
    $lastName: String
    $phone: String
    $password: String!
    $routeID: String
    $isActive: Boolean
  ) {
    editExecutive(
      id: $id
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      password: $password
      isActive: $isActive
      routeID: $routeID
    ) {
      id
      firstName
      lastName
      phone
      password
      routeID
      isActive
    }
  }
`;

export { MARK_ORDER, EDIT_EXECUTIVE };
