import { gql } from '@apollo/client';

const GET_LOCATION_BY_ID = gql`
  query GetLocationById($id: ID) {
    location(id: $id) {
      id
      name
      routeID
      regionID
    }
  }
`;

export default GET_LOCATION_BY_ID;
