import 'react-perfect-scrollbar/dist/css/styles.css';
import { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import './services/Firebase';
import routes from './routes';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  useEffect(() => {
    onAuthStateChanged(auth, (data) => {
      if (data) {
        setUser(data);
      } else {
        setUser(null);
      }
      setLoading(false);
    });
  }, [auth]);
  function signOutUser() {
    signOut(auth);
    setUser(null);
  }
  const routing = useRoutes(routes(user, loading, signOutUser));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
