import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { ADD_CATEGORY } from './categoryMutation';
import GET_CATEGORIES from './categoryQuery';
import Loading from '../../shared/Loading';

const AddCategory = (props) => {
  const [addCategory, addedCategoryData] = useMutation(ADD_CATEGORY, {
    // eslint-disable-next-line no-shadow
    update(cache, { data: { addCategory } }) {
      const existingCategories = cache.readQuery({ query: GET_CATEGORIES });
      cache.writeQuery({
        query: GET_CATEGORIES,
        data: {
          categories: [addCategory, ...existingCategories.categories]
        }
      });
    }
  });
  const initialState = {
    name: ''
  };
  const [values, setValues] = useState(initialState);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    addCategory({
      variables: {
        name: values.name
      }
    });
    setValues({ ...initialState });
  };

  if (addedCategoryData.loading) return <Loading />;
  if (addedCategoryData.error) return <p>Error...</p>;

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="You can only assign one category per Product."
          title="Add New Category"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Each category represents some products."
                label="Category Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Category
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AddCategory;
