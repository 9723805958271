import { gql } from '@apollo/client';

const ADD_LOCATION = gql`
  mutation AddLocation($name: String!, $regionID: String!, $routeID: String!) {
    addLocation(name: $name, regionID: $regionID, routeID: $routeID) {
      id
      name
      route {
        routeName
      }
      region {
        name
        hub {
          hubName
        }
      }
    }
  }
`;

const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      id
      name
    }
  }
`;

export { ADD_LOCATION, DELETE_LOCATION };
