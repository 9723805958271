import { gql } from '@apollo/client';

const GET_LOCATIONS = gql`
  {
    locations {
      id
      name
      route {
        routeName
      }
      region {
        name
        hub {
          hubName
        }
      }
    }
  }
`;

export default GET_LOCATIONS;
