const kGenerateBillUrl = '/bill/generate';
const kGetUserUrl = '/user';
const kGraphqlServerUrl = `${process.env.REACT_APP_SERVER_URL}/api`;
const kPayumoneyPaymentUrl = `${process.env.REACT_APP_SERVER_URL}/payment/payumoney`;
const kRegisterUrl = '/register';
const kTextLocalSend = '/textlocal/send';
const kOrderSheetExport = '/export/ordersheet';
const kCustomerSheetExport = '/export/customersheet';
const kOrgDetails = '/?orgID=shreesurbhi';

export {
  kGenerateBillUrl,
  kGetUserUrl,
  kGraphqlServerUrl,
  kRegisterUrl,
  kPayumoneyPaymentUrl,
  kTextLocalSend,
  kOrderSheetExport,
  kCustomerSheetExport,
  kOrgDetails
};
