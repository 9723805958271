import { Helmet } from 'react-helmet';
import Branding from '../components/billing/branding/Branding';
import DisplayBills from '../components/billing/bills/DisplayBills';

const Bills = () => (
  <>
    <Helmet>
      <title>Billing | Milkton</title>
    </Helmet>
    <Branding />
    <DisplayBills />
  </>
);

export default Bills;
