import { gql } from '@apollo/client';

const ADD_ORDER = gql`
  mutation AddOrder(
    $customerID: String!
    $routeID: String!
    $items: [Item]!
    $deliveryDate: DateTime!
    $addressID: String!
    $status: String
    $comment: String
  ) {
    addOrder(
      customerID: $customerID
      routeID: $routeID
      items: $items
      deliveryDate: $deliveryDate
      addressID: $addressID
      comment: $comment
      status: $status
    ) {
      id
      isSub
      items
      deliveryDate
      status
      comment
      customer {
        firstName
        lastName
        phone
        address
      }
    }
  }
`;
const DELETE_ORDER = gql`
  mutation DELETE_ORDER($id: ID!) {
    deleteOrder(id: $id) {
      id
      isSub
      items
      deliveryDate
      status
      comment
      customer {
        firstName
        lastName
        phone
        address
      }
    }
  }
`;

export { ADD_ORDER, DELETE_ORDER };
