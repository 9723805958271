import { gql } from '@apollo/client';

const ADD_SUBSCRIPTION = gql`
  mutation AddSubscription(
    $customerID: String!
    $items: [Item]!
    $startDate: DateTime!
    $endDate: DateTime
    $addressID: String!
    $frequency: Int!
    $status: String
  ) {
    addSubscription(
      customerID: $customerID
      items: $items
      startDate: $startDate
      endDate: $endDate
      addressID: $addressID
      frequency: $frequency
      status: $status
    ) {
      id
      customer {
        firstName
        lastName
        phone
        address
      }
      items
      nextDeliveryDate
      status
      frequency
    }
  }
`;

const DELETE_SUBSCRIPTION = gql`
  mutation DELETE_SUBSCRIPTION($id: ID!) {
    deleteSubscription(id: $id) {
      id
      customer {
        firstName
        lastName
        phone
        address
      }
      items
      nextDeliveryDate
      status
      frequency
    }
  }
`;

export { ADD_SUBSCRIPTION, DELETE_SUBSCRIPTION };
