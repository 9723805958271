import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { Box } from '@mui/material/';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel, a11yProps } from '../components/shared/TabPanel';
import AddRoute from '../components/executive/route/AddRoute';
import DisplayRoute from '../components/executive/route/DisplayRoute';
import AddExecutive from '../components/executive/executive/AddExecutive';
import DisplayExecutive from '../components/executive/executive/DisplayExecutive';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const Executive = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>Executive | Milkton</title>
      </Helmet>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Routes" {...a11yProps(0)} />
          <Tab label="Add Executive" {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <AddRoute />
        <Box p={3} />
        <DisplayRoute />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddExecutive />
        <Box p={3} />
        <DisplayExecutive />
      </TabPanel>
    </>
  );
};

export default Executive;
