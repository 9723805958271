import { gql } from '@apollo/client';

const GET_ORDERS_BY_TODAY = gql`
  {
    ordersForToday {
      id
      deliveryDate
      status
      items
      customer {
        location {
          region {
            hubID
            hub {
              hubName
            }
          }
        }
      }
    }
  }
`;

export default GET_ORDERS_BY_TODAY;
