import { Helmet } from 'react-helmet';
import OrderSummaryForToday from '../components/report/orderSummaryToday/DisplayOrderSummaryToday';

const Report = () => (
  <>
    <Helmet>
      <title>Reports | Milkton</title>
    </Helmet>
    <OrderSummaryForToday />
  </>
);

export default Report;
