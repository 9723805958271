/* eslint-disable operator-linebreak */
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  List,
  ListItem,
  Snackbar,
  IconButton,
  Typography,
  Alert
} from '@mui/material';

import EDIT_ORDER from './editOrderMutation';
import GET_ORDER from './editOrderQuery';
import { GET_PRODUCTS } from '../../product/product/productQuery';
import Loading from '../../shared/Loading';

const EditOrder = (props) => {
  const { id } = useParams();
  const {
    loading: orderLoading,
    error: orderError,
    data: orderData
  } = useQuery(GET_ORDER, {
    variables: { id }
  });
  const [editOrder, editedOrderData] = useMutation(EDIT_ORDER);
  // Snackbar
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };
  const snackbar = (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      style={{ marginTop: 50, width: 500 }}
      // message={snackbarMessage}
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          x
        </IconButton>
      }
    >
      <Alert severity="error" onClose={handleClose}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
  // Snackbar ends
  const initialProduct = {
    productID: '',
    quantity: ''
  };
  const productData = useQuery(GET_PRODUCTS);

  const [items, setItems] = useState([]);
  const initialState = {
    customerFirstName: '',
    customerLastName: '',
    customerPhone: '',
    status: '',
    addressName: '',
    deliveryDate: '',
    comment: ''
  };

  const [values, setValues] = useState(initialState);
  const [item, setItem] = useState(initialProduct);
  useEffect(() => {
    if (!orderLoading && !orderError) {
      const {
        // eslint-disable-next-line object-curly-newline
        order: { customer, items: alreadyItems, deliveryDate, status, comment }
      } = orderData;

      setValues({
        customerFirstName: customer.firstName,
        customerLastName: customer.lastName,
        customerPhone: customer.phone,
        deliveryDate: new Date(deliveryDate).toISOString().split('T')[0],
        comment,
        status,
        addressName: customer.address
      });
      alreadyItems.forEach((alreadyItem) => {
        setItems((current) => [...current, alreadyItem]);
      });
    }
  }, [orderLoading, orderError, orderData]);

  const handleChangeProduct = (event) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleAddProduct = () => {
    if (item.productID && item.quantity) {
      setItems((current) => [...current, item]);
      setItem(initialProduct);
    }
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleClear = () => {
    setItems([]);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (items.length > 0) {
      editOrder({
        variables: {
          id,
          items,
          deliveryDate: new Date(values.deliveryDate),
          status: values.status,
          comment: values.comment
        }
      });
    } else {
      setSnackbarMessage('Please add some product before creating Order.');
      setOpen(true);
    }
  };

  if (productData.loading) return <Loading />;
  if (productData.error) return `${productData.error}`;
  if (editedOrderData.loading) return <Loading />;
  if (editedOrderData.error) return `${editedOrderData.error}`;
  if (orderLoading) return <Loading />;
  if (orderError) return `${orderError}`;
  const totalProductData = productData.data.products;
  const customerDetail = `${values.customerFirstName} ${values.customerLastName} | ${values.customerPhone}`;

  function addedProductList() {
    return items.map((itemAdded) => {
      const requiredProduct = totalProductData.find(
        (foundProduct) => foundProduct.id === itemAdded.productID
      );
      const productListItem = `Product: ${requiredProduct.name}, Quantity: ${itemAdded.quantity}`;
      return <ListItem key={itemAdded.productID}>{productListItem}</ListItem>;
    });
  }

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="Don't update any empty data."
          title="Edit Order"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography>{customerDetail}</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>{values.addressName}</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Delivery Date*"
                name="deliveryDate"
                onChange={handleChange}
                required
                type="date"
                value={values.deliveryDate}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="status"
                onChange={handleChange}
                required
                value={values.status}
                variant="outlined"
              >
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="PENDING">PENDING</MenuItem>
              </Select>
              <FormHelperText>Status*</FormHelperText>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Any extra information, if required."
                label="Comment"
                name="comment"
                onChange={handleChange}
                value={values.comment}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <List>{addedProductList()}</List>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Button onClick={handleClear}>Clear Products</Button>
            </Grid>
            <Grid item lg={5} md={6} xs={12}>
              <Select
                fullWidth
                name="productID"
                onChange={handleChangeProduct}
                value={item.productID}
                variant="outlined"
              >
                {totalProductData.map((product) => (
                  <MenuItem value={product.id} key={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Product*</FormHelperText>
            </Grid>
            <Grid item lg={5} md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Quantity of product to be delivered."
                label="Quantity"
                name="quantity"
                onChange={handleChangeProduct}
                type="number"
                value={item.quantity}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddProduct}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Update Order
          </Button>
        </Box>
      </Card>
      {snackbar}
    </form>
  );
};

export default EditOrder;
