import { Card, CardHeader, Grid, Box, TextField, Button } from '@mui/material';
import { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import api from 'src/services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Download from 'src/utils/download';
import { kOrderSheetExport } from '../../../utils/apiConstants';

export default function OrderSheetExport() {
  const initialValue = {
    startDate: '',
    endDate: ''
  };
  const [values, setValues] = useState(initialValue);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = () => {
    if (!values.startDate || !values.endDate) {
      toast.error('Please select a date range');
    } else {
      api({
        method: 'post',
        url: kOrderSheetExport,
        data: values
      })
        .then(async (res) => {
          if (res.data.status !== 'Failed') {
            Download({
              name: `orderSheet(${values.startDate} to ${values.endDate}).csv`,
              data: res.data
            });
            toast.success('Download Started');
          } else {
            toast.error('Something went wrong');
          }
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    }
  };

  return (
    <Card>
      <CardHeader
        subheader="Export Order sheet in csv for a date range"
        title="Exports Orders"
      />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <TextField
              fullWidth
              helperText="Start Date*"
              name="startDate"
              onChange={handleChange}
              required
              type="date"
              value={values.startDate}
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <TextField
              fullWidth
              helperText="End Date*"
              name="endDate"
              onChange={handleChange}
              required
              type="date"
              value={values.endDate}
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        paddingBottom={2}
      >
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={handleSubmit}
        >
          Download CSV
        </Button>
      </Grid>
    </Card>
  );
}
