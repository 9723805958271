import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  List,
  ListItem,
  Snackbar,
  IconButton,
  Typography,
  Alert
} from '@mui/material';

import { ADD_SUBSCRIPTION } from './subscriptionMutation';
import GET_SUBSCRIPTIONS_BY_CUSTOMER_ID from './subscriptionQuery';
import GET_CUSTOMER_BY_ID from '../customerDetail/customerDetailQuery';
import { GET_PRODUCTS } from '../../product/product/productQuery';
import Loading from '../../shared/Loading';

const AddSubscription = (props) => {
  const { id } = useParams();
  const [addSubscription, addedSubscriptionData] = useMutation(
    ADD_SUBSCRIPTION,
    {
      // eslint-disable-next-line no-shadow
      update(cache, { data: { addSubscription } }) {
        const existingSubscriptions = cache.readQuery({
          query: GET_SUBSCRIPTIONS_BY_CUSTOMER_ID,
          variables: {
            customerID: id
          }
        });
        cache.writeQuery({
          query: GET_SUBSCRIPTIONS_BY_CUSTOMER_ID,
          variables: {
            customerID: id
          },
          data: {
            subscriptionsByCustomerID: [
              addSubscription,
              ...existingSubscriptions.subscriptionsByCustomerID
            ]
          }
        });
      }
    }
  );
  // Snackbar
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };
  const snackbar = (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      style={{ marginTop: 50, width: 500 }}
      // message={snackbarMessage}
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          x
        </IconButton>
      }
    >
      <Alert severity="error" onClose={handleClose}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
  // Snackbar ends
  const initialProduct = {
    productID: '',
    quantity: ''
  };
  const customerData = useQuery(GET_CUSTOMER_BY_ID, {
    variables: { id }
  });
  const productData = useQuery(GET_PRODUCTS);

  const [items, setItems] = useState([]);
  const initialState = {
    status: '',
    addressID: '1',
    startDate: '',
    endDate: '',
    frequency: ''
  };

  const [values, setValues] = useState(initialState);
  const [item, setItem] = useState(initialProduct);
  const handleChangeProduct = (event) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleAddProduct = () => {
    if (item.productID && item.quantity) {
      setItems((current) => [...current, item]);
      setItem(initialProduct);
    }
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (items.length > 0) {
      addSubscription({
        variables: {
          customerID: id,
          items,
          startDate: new Date(values.startDate),
          endDate: new Date(values.endDate),
          addressID: values.addressID,
          frequency: parseInt(values.frequency, 10),
          status: values.status
        }
      });
      setValues({ ...initialState });
      setItems([]);
    } else {
      setSnackbarMessage(
        'Please add some product before creating Subscripiotn.'
      );
      setOpen(true);
    }
  };

  if (productData.loading) return <Loading />;
  if (productData.error) return `${productData.error}`;
  if (customerData.loading) return <Loading />;
  if (customerData.error) return `${customerData.error}`;
  if (addedSubscriptionData.loading) return <Loading />;
  if (addedSubscriptionData.error) return `${addedSubscriptionData.error}`;
  const totalProductData = productData.data.products;
  function addedProductList() {
    return items.map((itemAdded) => {
      const requiredProduct = totalProductData.find(
        (foundProduct) => foundProduct.id === itemAdded.productID
      );
      const productListItem = `Product: ${requiredProduct.name}, Quantity: ${itemAdded.quantity}`;
      return <ListItem key={itemAdded.productID}>{productListItem}</ListItem>;
    });
  }

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="All fields are required."
          title="Add New Subscription"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography variant="h3">
                {`${customerData.data.customer.firstName} ${customerData.data.customer.lastName} - ${customerData.data.customer.phone}`}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>{customerData.data.customer.address}</Typography>
              <FormHelperText>Delivery Location*</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Subscription Start Date"
                name="startDate"
                onChange={handleChange}
                required
                type="date"
                value={values.startDate}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Subscription End Date (Optional)"
                name="endDate"
                onChange={handleChange}
                type="date"
                value={values.endDate}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="For Daily = 1, Alternate Day = 2 and So on"
                label="Frequency"
                required
                name="frequency"
                onChange={handleChange}
                type="number"
                value={values.frequency}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="status"
                onChange={handleChange}
                required
                value={values.status}
                variant="outlined"
              >
                <MenuItem value="PENDING">PENDING</MenuItem>
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="COMPLETED">COMPLETED</MenuItem>
              </Select>
              <FormHelperText>Status*</FormHelperText>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <List>{addedProductList()}</List>
            </Grid>
            <Grid item lg={5} md={6} xs={12}>
              <Select
                fullWidth
                name="productID"
                onChange={handleChangeProduct}
                value={item.productID}
                variant="outlined"
              >
                {totalProductData.map((product) => (
                  <MenuItem value={product.id} key={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Product*</FormHelperText>
            </Grid>
            <Grid item lg={5} md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Quantity of product to be delivered."
                label="Quantity"
                name="quantity"
                onChange={handleChangeProduct}
                type="number"
                value={item.quantity}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddProduct}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Subscription
          </Button>
        </Box>
      </Card>
      {snackbar}
    </form>
  );
};

export default AddSubscription;
