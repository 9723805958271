import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { EDIT_ADDRESS } from './addressMutation';
import Loading from '../../shared/Loading';
import { GET_ADDRESS_BY_ID } from './addressQuery';

const EditAddress = (props) => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_ADDRESS_BY_ID, {
    variables: { id }
  });

  const [editAddress, editedAddressData] = useMutation(EDIT_ADDRESS);
  const initialState = {
    name: ''
  };
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    if (!loading && !error) {
      setValues({
        name: data.address.name
      });
    }
  }, [data]);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    editAddress({
      variables: {
        name: values.name,
        id
      }
    });
  };

  if (loading) return <Loading />;
  if (error) return error;
  if (editedAddressData.loading) return <Loading />;
  if (editedAddressData.error) return `${editedAddressData.error}`;
  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="Wanna change delivery address?."
          title="Edit Address"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Please enter detailed address here."
                label="Complete Address"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Update Address
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default EditAddress;
