import { gql } from '@apollo/client';

const GET_ROUTES = gql`
  {
    routes {
      id
      routeName
      executive {
        firstName
        lastName
      }
    }
  }
`;

export default GET_ROUTES;
