import { gql } from '@apollo/client';

const EDIT_CUSTOMER = gql`
  mutation EditCustomer(
    $id: ID!
    $firstName: String
    $lastName: String
    $phone: String
    $locationID: String
    $email: String
    $address: String
  ) {
    editCustomer(
      id: $id
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      locationID: $locationID
      email: $email
      address: $address
    ) {
      id
      firstName
      lastName
      phone
      wallet
      address
      location {
        region {
          hub {
            hubName
          }
        }
        route {
          executive {
            firstName
            phone
          }
        }
      }
    }
  }
`;

export default EDIT_CUSTOMER;
