import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { Box, Paper, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabPanel, a11yProps } from '../components/shared/TabPanel';

import CustomerCard from '../components/customer/customerDetail/CustomerCard';
import DisplayOrder from '../components/customer/orders/DisplayOrder';
import AddOrder from '../components/customer/orders/AddOrder';
import AddSubscription from '../components/customer/subscription/AddSubscription';
import DisplaySubscription from '../components/customer/subscription/DisplaySubscription';
import AddTransaction from '../components/customer/transaction/AddTransaction';
import DisplayTransaction from '../components/customer/transaction/DisplayTransaction';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const CustomerDetail = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>Customer Detail | Milkton</title>
      </Helmet>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Info" {...a11yProps(0)} />
          <Tab label="Orders" {...a11yProps(1)} />
          <Tab label="Subscriptions" {...a11yProps(2)} />
          <Tab label="Transactions" {...a11yProps(3)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <CustomerCard />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddOrder />
        <Box p={3} />
        <DisplayOrder />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AddSubscription />
        <Box p={3} />
        <DisplaySubscription />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AddTransaction />
        <Box p={3} />
        <DisplayTransaction />
      </TabPanel>
    </>
  );
};

export default CustomerDetail;
