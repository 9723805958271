import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';

import EditCustomer from '../components/customer/customerEdit/EditCustomer';

const EditCustomerPage = () => (
  <>
    <Helmet>
      <title>Edit Customer | Milkton</title>
    </Helmet>
    <Box p={3}>
      <EditCustomer />
    </Box>
  </>
);

export default EditCustomerPage;
