/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid';
// eslint-disable-next-line object-curly-newline
import { Card, CardHeader, Button, Grid, Box, TextField } from '@mui/material';
import date from 'date-and-time';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CancelIcon from '@mui/icons-material/Cancel';
import { useQuery, useMutation } from '@apollo/client';
import { MARK_ORDER } from './executiveDetailMutation';
import { GET_EXECUTIVE_ORDERS_BY_ID_AND_DATE } from './executiveDetailQuery';
import { GET_PRODUCTS } from '../../../product/product/productQuery';
import ADD_TRANSACTION from '../../../customer/transaction/transactionMutation';
import Loading from '../../../shared/Loading';

function ExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
export default function DisplayExecutiveOrders() {
  const now = new Date();
  const today = date.addHours(now, 9);
  const [filterDate, setFilterDate] = useState(
    today.toISOString().split('T')[0]
  );
  const [selectedRow, setSelectedRow] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [markOrder, markedOrderData] = useMutation(MARK_ORDER);
  const [addTransaction, addedTransactionData] = useMutation(ADD_TRANSACTION);
  const products = useQuery(GET_PRODUCTS);
  const { id } = useParams();
  const { loading, error, data } = useQuery(
    GET_EXECUTIVE_ORDERS_BY_ID_AND_DATE,
    {
      variables: { executiveID: id, date: filterDate }
    }
  );

  if (loading) return <Loading />;
  if (error) return `${error}`;
  if (products.loading) return <Loading />;
  if (products.error) return `${products.error}`;
  if (markedOrderData.loading) return <Loading />;
  if (markedOrderData.error) return `${markedOrderData.error}`;
  if (addedTransactionData.loading) return <Loading />;
  if (addedTransactionData.error) return `${addedTransactionData.error}`;
  // if (isLoading) return <Loading />;
  const productData = products.data.products;
  function calculateTotal(items) {
    let subTotal = 0;
    items.forEach((item) => {
      const requiredProduct = productData.find(
        (product) => product.id === item.productID
      );
      subTotal += requiredProduct.price * item.quantity;
    });
    return subTotal;
  }
  const handleChange = (event) => {
    setFilterDate(event.target.value);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Customer Name',
      width: 160,
      valueGetter: (params) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        `${params.row.customer.firstName} ${params.row.customer.lastName}`
    },
    {
      field: 'phone',
      headerName: 'Mobile',
      width: 120,
      valueGetter: (params) => params.row.customer.phone
    },
    {
      field: 'items',
      headerName: 'Items',
      width: 180,
      valueGetter: (params) => {
        let items = '';
        if (params.row.items[0] != null) {
          params.row.items.forEach((item) => {
            const requiredProduct = productData.find(
              (product) => product.id === item.productID
            );
            items += `${requiredProduct.name}(${item.quantity}), `;
          });
          return items.slice(0, -2);
        }
        return 'No Product Found';
      }
    },
    {
      field: 'nextDate',
      headerName: 'Delivery Date',
      width: 150,
      valueGetter: (params) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        new Date(params.row.deliveryDate).toDateString()
    },
    {
      field: 'isSub',
      headerName: 'Type',
      width: 120,
      valueGetter: (params) => {
        if (params.row.isSub) {
          return 'Subscription';
        }
        return 'One Time';
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130
    },
    {
      field: 'initialQuantity',
      headerName: 'Cron Qty',
      width: 80
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 140
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 280,
      valueGetter: (params) => params.row.customer.address
    },
    {
      field: 'undeliver',
      headerName: 'Un-Deliver',
      width: 140,
      sortable: false,
      renderCell: (params) =>
        params.row.status === 'ACTIVE' ? (
          <strong>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                // eslint-disable-next-line eqeqeq
                if (params.row.status === 'ACTIVE') {
                  markOrder({
                    variables: {
                      id: params.id,
                      status: 'UNDELIVERED'
                    }
                  });
                } else {
                  console.log('Already Undelivered');
                }
                // eslint-disable-next-line no-alert
                alert('All Undeliver Marked');
              }}
              startIcon={<CancelIcon />}
            >
              UN-DELIVER
            </Button>
          </strong>
        ) : null
    },
    {
      field: 'deliver',
      headerName: 'Deliver',
      width: 120,
      sortable: false,
      renderCell: (params) =>
        params.row.status === 'ACTIVE' ? (
          <strong>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                // eslint-disable-next-line eqeqeq
                if (params.row.status === 'ACTIVE') {
                  markOrder({
                    variables: {
                      id: params.id,
                      status: 'DELIVERED'
                    }
                  });
                  addTransaction({
                    variables: {
                      customerID: params.row.customer.id,
                      orderID: params.id,
                      date: new Date(),
                      isDebit: true,
                      subTotal: parseInt(calculateTotal(params.row.items), 10),
                      comment: 'Paid for Order',
                      orgID: 'shreesurbhi'
                    }
                  });
                } else {
                  console.log('Already Deliverd');
                }
                // eslint-disable-next-line no-alert
                alert('All Deliver Marked');
              }}
              startIcon={<LocalShippingIcon />}
            >
              DELIVER
            </Button>
          </strong>
        ) : null
    }
  ];
  const rows = data.ordersByExecutiveIDAndDate;

  return (
    <Card>
      <CardHeader
        subheader="Bulk action will skip already marked orders."
        title="Executive Order"
      />
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <TextField
              fullWidth
              helperText="Filter Date*"
              name="filterDate"
              onChange={handleChange}
              required
              type="date"
              value={filterDate}
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                setIsLoading(true);
                selectedRow.forEach((selection) => {
                  const requiredRow = rows.find(
                    (foundRow) => foundRow.id === selection
                  );
                  if (requiredRow.status === 'ACTIVE') {
                    markOrder({
                      variables: {
                        id: requiredRow.id,
                        status: 'UNDELIVERED'
                      }
                    });
                  } else {
                    console.log(`Already ${requiredRow.status}`);
                  }
                });
                setIsLoading(false);
                alert('All Undeliver Marked');
              }}
              startIcon={<CancelIcon />}
            >
              BULK UN-DELIVER
            </Button>
          </Box>
        </Grid> */}
        {/* <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              p: 2
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setIsLoading(true);
                selectedRow.forEach((selection) => {
                  const requiredRow = rows.find(
                    (foundRow) => foundRow.id === selection
                  );
                  if (requiredRow.status === 'ACTIVE') {
                    markOrder({
                      variables: {
                        id: requiredRow.id,
                        status: 'DELIVERED'
                      }
                    });
                    addTransaction({
                      variables: {
                        customerID: requiredRow.customer.id,
                        orderID: requiredRow.id,
                        date: new Date(),
                        isDebit: true,
                        subTotal: parseInt(
                          calculateTotal(requiredRow.items),
                          10
                        ),
                        comment: 'Paid for Order'
                      }
                    });
                  } else {
                    console.log(`Already ${requiredRow.status}`);
                  }
                });
                setIsLoading(false);
                alert('All Deliver Marked');
              }}
              startIcon={<LocalShippingIcon />}
            >
              BULK DELIVER
            </Button>
          </Box>
        </Grid> */}
      </Grid>
      <div style={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={25}
          autoHeight
          selectionModel={selectedRow}
          checkboxSelection
          onSelectionModelChange={(newSelection) => {
            setSelectedRow(newSelection.selectionModel);
          }}
          components={{
            Toolbar: ExportButton
          }}
        />
      </div>
    </Card>
  );
}
