import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { ADD_PRODUCT } from './productMutation';
import { GET_PRODUCTS } from './productQuery';
import GET_CATEGORIES from '../category/categoryQuery';
import GET_HUBS from '../../location/hub/hubQuery';
import Loading from '../../shared/Loading';

const AddProduct = (props) => {
  const [addProduct, addedProductData] = useMutation(ADD_PRODUCT, {
    // eslint-disable-next-line no-shadow
    update(cache, { data: { addProduct } }) {
      const existingProducts = cache.readQuery({ query: GET_PRODUCTS });
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: {
          products: [addProduct, ...existingProducts.products]
        }
      });
    }
  });
  const { loading, error, data } = useQuery(GET_CATEGORIES);
  const hubsQuery = useQuery(GET_HUBS);
  const initialState = {
    name: '',
    price: 0,
    description: '',
    categoryID: '',
    statusHub: []
  };
  const [values, setValues] = useState(initialState);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    addProduct({
      variables: {
        name: values.name,
        price: parseInt(values.price, 10),
        description: values.description,
        categoryID: values.categoryID,
        statusHub: values.statusHub
      }
    });
    setValues({ ...initialState });
  };
  if (loading) return <Loading />;
  if (error) return 'Error in Fetching Category';
  if (hubsQuery.loading) return <Loading />;
  if (hubsQuery.error) return 'Error in Fetching Hubs';
  if (addedProductData.loading) return <Loading />;
  if (addedProductData.error) return <p>Error in adding product.</p>;

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="Product's availability can be set by defining it's Hub Status."
          title="Add New Product"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Product Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="All the prices are considered in INR by default."
                label="Price"
                name="price"
                onChange={handleChange}
                type="number"
                required
                value={values.price}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Product Description"
                name="description"
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="categoryID"
                onChange={handleChange}
                required
                value={values.categoryID}
                variant="outlined"
              >
                {data.categories.map((category) => (
                  <MenuItem value={category.id} key={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Assign Category*</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="statusHub"
                multiple
                onChange={handleChange}
                required
                value={values.statusHub}
                variant="outlined"
              >
                {hubsQuery.data.hubs.map((hub) => (
                  <MenuItem value={hub.id} key={hub.id}>
                    {hub.hubName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Hub Status*</FormHelperText>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Product
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AddProduct;
