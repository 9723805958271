import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';

import EditOrder from '../components/order/orderEdit/EditOrder';

const EditOrderPage = () => (
  <>
    <Helmet>
      <title>Edit Order | Milkton</title>
    </Helmet>
    <Box p={3}>
      <EditOrder />
    </Box>
  </>
);

export default EditOrderPage;
