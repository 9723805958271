import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import GET_HUBS from '../hub/hubQuery';
import GET_REGIONS from './regionQuery';
import { ADD_REGION } from './regionMutation';
import Loading from '../../shared/Loading';

const AddRegion = (props) => {
  const [addRegion, addedRegionData] = useMutation(ADD_REGION, {
    // eslint-disable-next-line no-shadow
    update(cache, { data: { addRegion } }) {
      const existingRegions = cache.readQuery({ query: GET_REGIONS });
      cache.writeQuery({
        query: GET_REGIONS,
        data: {
          regions: [addRegion, ...existingRegions.regions]
        }
      });
    }
  });
  const { loading, error, data } = useQuery(GET_HUBS);
  const initialValues = {
    regionName: '',
    hubId: ''
  };
  const [values, setValues] = useState(initialValues);
  const handleSubmit = (event) => {
    event.preventDefault();
    addRegion({
      variables: {
        name: values.regionName,
        hubID: values.hubId
      }
    });
    setValues({ ...initialValues });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  if (loading) return <Loading />;
  if (error) return 'Error!';
  if (addedRegionData.loading) return <Loading />;
  if (addedRegionData.error) return <p>Error...</p>;
  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="Every Region must be assigned to exactly one Hub."
          title="Add New Region"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="This will be displayed on App Side."
                label="Region Name"
                name="regionName"
                onChange={handleChange}
                required
                value={values.regionName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                label="hubId"
                name="hubId"
                onChange={handleChange}
                required
                value={values.hubId}
                variant="outlined"
              >
                {data.hubs.map((hub) => (
                  <MenuItem value={hub.id} key={hub.id}>
                    {hub.hubName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Hub*</FormHelperText>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Region
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AddRegion;
