import { NavLink as RouterLink } from 'react-router-dom';
// import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import ListDataGrid from 'src/components/listGrid';
import GET_PAGINATED_CUSTOMERS from './customerPaginatedQuery';

// function ExportButton() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

export default function DisplayCustomer() {
  // const { loading, error, data } = useQuery(GET_CUSTOMERS);
  // if (loading) return <Loading />;
  // if (error) return 'Error!';
  const month = `0${new Date().getMonth()}`.slice(-2);
  const billMonth = month !== '00' ? `b${month}` : 'b12';

  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 150 },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 150
    },
    {
      field: 'phone',
      headerName: 'Mobile',

      width: 150
    },
    {
      field: 'wallet',
      headerName: 'Wallet',
      type: 'Number',
      width: 100
    },
    {
      field: billMonth,
      headerName: `${billMonth}`,
      type: 'Number',
      width: 100
    },
    {
      field: 'executive',
      headerName: 'Executive Assigned',
      sortable: false,
      width: 200,
      valueGetter: (params) => {
        let executiveAssigned = '';
        if (params.row.location.route.executive[0] != null) {
          params.row.location.route.executive.forEach((executive) => {
            executiveAssigned += `${executive.firstName} (${executive.phone}), `;
          });
          return executiveAssigned.slice(0, -2);
        }
        return 'Not Assigned';
      }
    },
    {
      field: 'hub',
      headerName: 'Hub Assigned',
      width: 150,
      valueGetter: (params) => params.row.location.region.hub.hubName
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 150,
      valueGetter: (params) => params.row.location.name
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 350
    },
    {
      field: 'view',
      headerName: 'More',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Button
            component={RouterLink}
            variant="contained"
            color="primary"
            size="small"
            // eslint-disable-next-line react/destructuring-assignment
            to={params.id}
            startIcon={<LaunchIcon />}
          >
            VIEW
          </Button>
        </strong>
      )
    }
  ];
  // const rows = data.customers;

  const gridProps = {
    query: GET_PAGINATED_CUSTOMERS,
    limit: 25,
    columns,
    queryName: 'paginatedCustomer',
    title: 'Customer'
  };

  return <ListDataGrid gridProps={gridProps} />;

  // return (
  //   <Card>
  //     <CardHeader subheader="Currently added Customers." title="Customers" />
  //     <div style={{ width: '100%' }}>
  //       <DataGrid
  //         rows={rows}
  //         columns={columns}
  //         pageSize={25}
  //         autoHeight
  //         components={{
  //           Toolbar: ExportButton
  //         }}
  //       />
  //     </div>
  //   </Card>
  // );
}
