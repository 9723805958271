import { gql } from '@apollo/client';

const ADD_CUSTOMER = gql`
  mutation AddCustomer(
    $firstName: String!
    $lastName: String!
    $phone: String!
    $locationID: String!
    $email: String
    $address: String
  ) {
    addCustomer(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      locationID: $locationID
      email: $email
      address: $address
    ) {
      id
      firstName
      lastName
      phone
      wallet
      address
      location {
        region {
          hub {
            hubName
          }
        }
        route {
          executive {
            firstName
            phone
          }
        }
      }
    }
  }
`;

export default ADD_CUSTOMER;
