import { DataGrid } from '@mui/x-data-grid';
import { Card, CardHeader, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from '@apollo/client';
import GET_REGIONS from './regionQuery';
import Loading from '../../shared/Loading';

export default function DisplayRegion() {
  const { loading, error, data } = useQuery(GET_REGIONS);
  if (loading) return <Loading />;
  if (error) return 'Error!';

  const columns = [
    {
      field: 'name',
      headerName: 'Region Name',
      width: 650
    },
    {
      field: 'hubName',
      headerName: 'Hub Name',
      width: 300,
      valueGetter: (params) => params.row.hub.hubName
    },

    {
      field: 'edit',
      headerName: 'Edit',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              // eslint-disable-next-line no-alert
              alert(params.id);
            }}
            startIcon={<EditIcon />}
          >
            EDIT
          </Button>
        </strong>
      )
    }
  ];
  const rows = data.regions;

  return (
    <Card>
      <CardHeader subheader="Currently added Regions." title="Regions" />
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={10} />
      </div>
    </Card>
  );
}
