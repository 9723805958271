import { gql } from '@apollo/client';

const ADD_CATEGORY = gql`
  mutation AddCategory($name: String!) {
    addCategory(name: $name) {
      id
      name
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation DELETE_CATEGORY($id: ID!) {
    deleteCategory(id: $id) {
      id
      name
    }
  }
`;

export { DELETE_CATEGORY, ADD_CATEGORY };
