import { DataGrid } from '@mui/x-data-grid';
import { Card, CardHeader, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS } from './productQuery';
import Loading from '../../shared/Loading';

export default function DisplayProduct() {
  const { loading, error, data } = useQuery(GET_PRODUCTS);
  if (loading) return <Loading />;
  if (error) return 'Error!';

  const columns = [
    { field: 'name', headerName: 'Name', width: 250 },
    {
      field: 'price',
      headerName: 'Price',
      type: 'Number',
      width: 100
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 200,
      valueGetter: (params) => params.row.category.name
    },
    {
      field: 'hubs',
      headerName: 'Hubs',
      sortable: false,
      width: 300,
      valueGetter: (params) => {
        let availableHubs = '';
        if (params.row.hubs[0] != null) {
          params.row.hubs.forEach((hub) => {
            availableHubs += `${hub.hubName}, `;
          });
          return availableHubs.slice(0, -2);
        }
        return 'No Hub Assigned';
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      width: 350
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              // eslint-disable-next-line no-alert
              alert(params.id);
            }}
            startIcon={<EditIcon />}
          >
            EDIT
          </Button>
        </strong>
      )
    }
  ];
  const rows = data.products;

  return (
    <Card>
      <CardHeader subheader="Currently added Products." title="Products" />
      <div style={{ width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={25} autoHeight />
      </div>
    </Card>
  );
}
