/* eslint-disable operator-linebreak */
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  List,
  ListItem,
  Snackbar,
  IconButton,
  Typography,
  Alert
} from '@mui/material';

import EDIT_SUBSCRIPTION from './editSubscriptionMutation';
import GET_SUBSCRIPTION from './editSubscriptionQuery';
import { GET_PRODUCTS } from '../../product/product/productQuery';
import Loading from '../../shared/Loading';

const EditSubsctiption = (props) => {
  const { id } = useParams();
  const {
    loading: subscriptionLoading,
    error: subscriptionError,
    data: subscriptionData
  } = useQuery(GET_SUBSCRIPTION, {
    variables: { id }
  });
  const [editSubscription, editedSubscriptionData] =
    useMutation(EDIT_SUBSCRIPTION);
  // Snackbar
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };
  const snackbar = (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      style={{ marginTop: 50, width: 500 }}
      // message={snackbarMessage}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          x
        </IconButton>
      }
    >
      <Alert severity="error" onClose={handleClose}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
  // Snackbar ends
  const initialProduct = {
    productID: '',
    quantity: ''
  };
  const productData = useQuery(GET_PRODUCTS);

  const [items, setItems] = useState([]);
  const initialState = {
    customerFirstName: '',
    customerLastName: '',
    customerPhone: '',
    status: '',
    addressName: '',
    nextDeliveryDate: '',
    endDate: '',
    frequency: ''
  };

  const [values, setValues] = useState(initialState);
  const [item, setItem] = useState(initialProduct);
  useEffect(() => {
    if (!subscriptionLoading && !subscriptionError) {
      const {
        subscription: {
          customer,
          items: alreadyItems,
          nextDeliveryDate,
          endDate,
          frequency,
          status
        }
      } = subscriptionData;

      setValues({
        customerFirstName: customer.firstName,
        customerLastName: customer.lastName,
        customerPhone: customer.phone,
        nextDeliveryDate: new Date(nextDeliveryDate)
          .toISOString()
          .split('T')[0],
        endDate,
        frequency,
        status,
        addressName: customer.address
      });
      alreadyItems.forEach((alreadyItem) => {
        setItems((current) => [...current, alreadyItem]);
      });
    }
  }, [subscriptionLoading, subscriptionError, subscriptionData]);

  const handleChangeProduct = (event) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleAddProduct = () => {
    if (item.productID && item.quantity) {
      setItems((current) => [...current, item]);
      setItem(initialProduct);
    }
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleClear = () => {
    setItems([]);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (items.length > 0) {
      editSubscription({
        variables: {
          id,
          items,
          nextDeliveryDate: new Date(values.nextDeliveryDate),
          endDate: new Date(values.endDate),
          frequency: parseInt(values.frequency, 10),
          status: values.status
        }
      });
    } else {
      setSnackbarMessage(
        'Please add some product before creating Subscripiotn.'
      );
      setOpen(true);
    }
  };

  if (productData.loading) return <Loading />;
  if (productData.error) return `${productData.error}`;
  if (editedSubscriptionData.loading) return <Loading />;
  if (editedSubscriptionData.error) return `${editedSubscriptionData.error}`;
  if (subscriptionLoading) return <Loading />;
  if (subscriptionError) return `${subscriptionError}`;
  const totalProductData = productData.data.products;
  const customerDetail = `${values.customerFirstName} ${values.customerLastName} | ${values.customerPhone}`;

  // const removeProduct = (addedProductID) => {
  //   console.log(addedProductID);
  //   for (let i = 0; i < items.length; i++) {
  //     console.log(items[i]);
  //     // eslint-disable-next-line eqeqeq
  //     if (items[i].productID == addedProductID) {
  //       items.splice(i, 1);
  //     }
  //   }
  //   setItems((current) => [...current, items]);
  // };
  function addedProductList() {
    return items.map((itemAdded) => {
      const requiredProduct = totalProductData.find(
        (foundProduct) => foundProduct.id === itemAdded.productID
      );
      const productListItem = `Product: ${requiredProduct.name}, Quantity: ${itemAdded.quantity}`;
      return (
        <ListItem key={itemAdded.productID}>
          {productListItem}
          {/* <Button
            onClick={() => {
              removeProduct(itemAdded.productID);
            }}
          >
            remove
          </Button> */}
        </ListItem>
      );
    });
  }

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="Don't update any empty data."
          title="Edit Subscription"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography>{customerDetail}</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>{values.addressName}</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Next Delivery Date*"
                name="nextDeliveryDate"
                onChange={handleChange}
                required
                type="date"
                value={values.nextDeliveryDate}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Subscription End Date (Optional)"
                name="endDate"
                onChange={handleChange}
                type="date"
                value={values.endDate}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="For Daily = 1, Alternate Day = 2 and So on"
                label="Frequency"
                required
                name="frequency"
                onChange={handleChange}
                type="number"
                value={values.frequency}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="status"
                onChange={handleChange}
                required
                value={values.status}
                variant="outlined"
              >
                <MenuItem value="PENDING">PENDING</MenuItem>
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="COMPLETED">COMPLETED</MenuItem>
              </Select>
              <FormHelperText>Status*</FormHelperText>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <List>{addedProductList()}</List>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Button onClick={handleClear}>Clear Products</Button>
            </Grid>
            <Grid item lg={5} md={6} xs={12}>
              <Select
                fullWidth
                name="productID"
                onChange={handleChangeProduct}
                value={item.productID}
                variant="outlined"
              >
                {totalProductData.map((product) => (
                  <MenuItem value={product.id} key={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Product*</FormHelperText>
            </Grid>
            <Grid item lg={5} md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Quantity of product to be delivered."
                label="Quantity"
                name="quantity"
                onChange={handleChangeProduct}
                type="number"
                value={item.quantity}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddProduct}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Update Subscription
          </Button>
        </Box>
      </Card>
      {snackbar}
    </form>
  );
};

export default EditSubsctiption;
