import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel, a11yProps } from '../components/shared/TabPanel';
import AddHub from '../components/location/hub/AddHub';
import DisplayHub from '../components/location/hub/DisplayHub';
import AddRegion from '../components/location/region/AddRegion';
import DisplayRegion from '../components/location/region/DisplayRegion';
import AddLocation from '../components/location/location/AddLocation';
import DisplayLocation from '../components/location/location/DisplayLocation';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const Location = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>Location | Milkton</title>
      </Helmet>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Hubs" {...a11yProps(0)} />
          <Tab label="Regions" {...a11yProps(1)} />
          <Tab label="Locations" {...a11yProps(2)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <AddHub />
        <Box p={3} />
        <DisplayHub />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddRegion />
        <Box p={3} />
        <DisplayRegion />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AddLocation />
        <Box p={3} />
        <DisplayLocation />
      </TabPanel>
    </>
  );
};

export default Location;
