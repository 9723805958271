import { Helmet } from 'react-helmet';
import AddTransaction from 'src/components/transaction/AddTransaction';

import { Box } from '@mui/material';

import DisplayCreditTransaction
  from '../components/transaction/DisplayTransaction';

const Transaction = () => (
  <>
    <Helmet>
      <title>Transactions | Milkton</title>
    </Helmet>
    <AddTransaction />
    <Box p={3} />
    <DisplayCreditTransaction />
  </>
);

export default Transaction;
