import { gql } from '@apollo/client';

const ADD_PRODUCT = gql`
  mutation AddProduct(
    $name: String!
    $categoryID: String!
    $description: String
    $statusHub: [String]!
    $price: Int!
    $photoURL: String
  ) {
    addProduct(
      name: $name
      categoryID: $categoryID
      description: $description
      statusHub: $statusHub
      price: $price
      photoURL: $photoURL
    ) {
      id
      name
      price
      description
      category {
        name
      }
      hubs {
        hubName
      }
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DELETE_PRODUCT($id: ID!) {
    deleteProduct(id: $id) {
      id
      name
      price
      description
      category {
        name
      }
      hubs {
        hubName
      }
    }
  }
`;

export { DELETE_PRODUCT, ADD_PRODUCT };
