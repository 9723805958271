import { gql } from '@apollo/client';

const GET_TRANSACTION_BY_CUSTOMER_ID_AND_DATE = gql`
  query TransactionsByCustomerIDAndDate(
    $customerID: String
    $startDate: Date
    $endDate: Date
  ) {
    transactionsByCustomerIDAndDate(
      customerID: $customerID
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      orderID
      subTotal
      isDebit
      date
      comment
      customer {
        firstName
        lastName
        phone
      }
    }
  }
`;

export default GET_TRANSACTION_BY_CUSTOMER_ID_AND_DATE;
