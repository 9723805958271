import { DataGrid } from '@mui/x-data-grid';
import { Card, CardHeader, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from '@apollo/client';
import GET_ROUTES from './routeQuery';
import Loading from '../../shared/Loading';

export default function DisplayRoute() {
  const { loading, error, data } = useQuery(GET_ROUTES);
  if (loading) return <Loading />;
  if (error) return 'Error!';

  const columns = [
    { field: 'routeName', headerName: 'Route Name', width: 350 },
    {
      field: 'executive',
      headerName: 'Executive Assigned',
      width: 600,
      valueGetter: (params) => {
        let executiveAssigned = '';
        if (params.row.executive[0] != null) {
          params.row.executive.forEach((executive) => {
            executiveAssigned += `${executive.firstName} ${executive.lastName}, `;
          });
          return executiveAssigned.slice(0, -2);
        }
        return 'Not Assigned';
      }
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              // eslint-disable-next-line no-alert
              alert(params.id);
            }}
            startIcon={<EditIcon />}
          >
            EDIT
          </Button>
        </strong>
      )
    }
  ];
  const rows = data.routes;

  return (
    <Card>
      <CardHeader subheader="Present Routes." title="Routes" />
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} />
      </div>
    </Card>
  );
}
