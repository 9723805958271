/* eslint-disable operator-linebreak */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  Typography
} from '@mui/material';

import ADD_TRANSACTION from './transactionMutation';
import GET_CUSTOMER_BY_ID from '../customerDetail/customerDetailQuery';
import Loading from '../../shared/Loading';

const AddTransaction = (props) => {
  const { id: customerID } = useParams();
  const [addTransaction, addedTransactionData] = useMutation(ADD_TRANSACTION);

  const customerData = useQuery(GET_CUSTOMER_BY_ID, {
    variables: { id: customerID }
  });
  const initialState = {
    subTotal: '',
    isDebit: false,
    comment: ''
  };

  const [values, setValues] = useState(initialState);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    addTransaction({
      variables: {
        customerID,
        date: new Date(),
        isDebit: values.isDebit,
        subTotal: parseInt(values.subTotal, 10),
        comment: values.comment,
        orgID: 'shreesurbhi'
      }
    });
    setValues({ ...initialState });
  };

  if (customerData.loading) return <Loading />;
  if (customerData.error) return `${customerData.error}`;
  if (addedTransactionData.loading) return <Loading />;
  if (addedTransactionData.error) return `${addedTransactionData.error}`;

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="All fields are required."
          title="Add New Transaction"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography variant="h3">
                {`${customerData.data.customer.firstName} ${customerData.data.customer.lastName} - ${customerData.data.customer.phone}`}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Total Amount in Whole Numbers Only"
                required
                label="Amount"
                name="subTotal"
                onChange={handleChange}
                value={values.subTotal}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="isDebit"
                onChange={handleChange}
                required
                value={values.isDebit}
                variant="outlined"
              >
                <MenuItem value>DEBIT</MenuItem>
                <MenuItem value={false}>CREDIT</MenuItem>
              </Select>
              <FormHelperText>Type of Transaction*</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Any extra information, if required."
                label="Comment"
                name="comment"
                onChange={handleChange}
                value={values.comment}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Transaction
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AddTransaction;
