import { gql } from '@apollo/client';

const GET_ORDERS_BY_CUSTOMER_AND_DATE_RANGE = gql`
  query OrdersByCustomerIDAndDate(
    $customerID: String
    $startDate: Date
    $endDate: Date
  ) {
    ordersByCustomerIDAndDate(
      customerID: $customerID
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      isSub
      items
      initialQuantity
      deliveryDate
      status
      comment
      customer {
        address
      }
    }
  }
`;

export default GET_ORDERS_BY_CUSTOMER_AND_DATE_RANGE;
