import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { Box } from '@mui/material/';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel, a11yProps } from '../components/shared/TabPanel';
import AddProduct from '../components/product/product/AddProduct';
import DisplayProduct from '../components/product/product/DisplayProduct';
import AddCategory from '../components/product/category/AddCategory';
import DisplayCategory from '../components/product/category/DisplayCategory';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const Product = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>Product | Milkton</title>
      </Helmet>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Products" {...a11yProps(0)} />
          <Tab label="Category" {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <AddProduct />
        <Box p={3} />
        <DisplayProduct />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddCategory />
        <Box p={3} />
        <DisplayCategory />
      </TabPanel>
    </>
  );
};

export default Product;
