import { Navigate } from 'react-router-dom';
import { NotFound, Export } from './pages';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import BillLayout from './components/BillLayout';
import Loading from './components/shared/Loading';
import Login from './pages/Login';
import Account from './pages/Account';
import Customer from './pages/Customer';
import Subscription from './pages/Subscription';
import EditSubscription from './pages/EditSubcription';
import EditOrderPage from './pages/EditOrder';
import Order from './pages/Order';
import Product from './pages/Product';
import Register from './pages/Register';
import Settings from './pages/Settings';
import Location from './pages/Location';
import Executive from './pages/Executive';
import CustomerDetail from './pages/CustomerDetail';
import ExecutiveDetail from './pages/ExecutiveDetail';
import Bills from './pages/Billing';
import Report from './pages/Report';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentFailed from './pages/PaymentFailed';
import Transaction from './pages/Transaction';
import EditCustomerPage from './pages/EditCustomer';
import EditLocationPage from './pages/EditLocation';
import EditAddressPage from './pages/EditAddress';
import Dashboard from './pages/Dashboard';

const routes = (user, loading, signOutUser) => [
  {
    path: 'app',
    // eslint-disable-next-line no-nested-ternary
    element: !loading ? (
      user ? (
        <DashboardLayout signOutUser={signOutUser} />
      ) : (
        <Navigate to="/login" />
      )
    ) : (
      <Loading />
    ),
    children: [
      { path: '', element: <Navigate to="/app/dashboard" /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'customers', element: <Customer /> },
      { path: 'orders', element: <Order /> },
      { path: 'orders/:id', element: <EditOrderPage /> },
      { path: 'subscriptions', element: <Subscription /> },
      { path: 'subscriptions/:id', element: <EditSubscription /> },
      { path: 'customers/:id', element: <CustomerDetail /> },
      { path: 'customers/:id/edit', element: <EditCustomerPage /> },
      { path: 'address/:id', element: <EditAddressPage /> },
      { path: 'products', element: <Product /> },
      { path: 'location', element: <Location /> },
      { path: 'location/:id', element: <EditLocationPage /> },
      { path: 'executive', element: <Executive /> },
      { path: 'executive/:id', element: <ExecutiveDetail /> },
      { path: 'transactions', element: <Transaction /> },
      { path: 'reports', element: <Report /> },
      { path: 'exports', element: <Export /> },
      { path: 'account', element: <Account /> },
      { path: 'settings', element: <Settings /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/app/404" /> }
    ]
  },
  {
    path: '/bill',
    element: <BillLayout />,
    children: [
      { path: ':customerID/:mm/:yyyy', element: <Bills /> },
      { path: 'success', element: <PaymentSuccess /> },
      { path: 'fail', element: <PaymentFailed /> },
      { path: '404', element: <NotFound /> },
      { path: '', element: <Navigate to="/bill/404" /> },
      { path: '*', element: <Navigate to="/bill/404" /> }
    ]
  },
  {
    path: '',
    element: !user ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  }
];

export default routes;
