import { gql } from '@apollo/client';

const ADD_ROUTE = gql`
  mutation AddRoute($routeName: String!) {
    addRoute(routeName: $routeName) {
      id
      routeName
    }
  }
`;

const DELETE_ROUTE = gql`
  mutation DELETE_ROUTE($id: ID!) {
    deleteRoute(id: $id) {
      id
      routeName
    }
  }
`;

export { DELETE_ROUTE, ADD_ROUTE };
