import { useQuery } from '@apollo/client';
import { Box, Divider, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import CustomPagination from '../customPagination';

export default function ListDataGrid({ gridProps }) {
  const [page, setPage] = useState(1);
  const limit = gridProps.limit || 25;
  const { data, loading, error } = useQuery(gridProps.query, {
    variables: {
      limit,
      page
    }
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error.toString()}</div>;
  const rows = data[gridProps.queryName].docs;
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: 'background.paper',
        flexGrow: 1,
        py: 8,
        overflow: 'hidden'
      }}
    >
      <Box sx={{ m: 4 }}>
        <Typography variant="h4">{gridProps.title}</Typography>
      </Box>
      <Divider />
      <Box sx={{ width: '100%', px: 3 }}>
        <DataGrid
          rows={rows}
          columns={gridProps.columns}
          pageSize={limit}
          autoHeight
          rowsPerPageOptions={[limit]}
          onPageChange={(params) => setPage(params + 1)}
          page={page - 1}
          pagination
          loading={loading}
          paginationMode="server"
          rowCount={data[gridProps.queryName].totalDocs}
          components={{
            Pagination: CustomPagination
          }}
        />
      </Box>
    </Box>
  );
}
