import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  List,
  ListItem,
  IconButton,
  Snackbar,
  Typography,
  Alert
} from '@mui/material';

import { ADD_ORDER } from './orderMutation';
import GET_CUSTOMER_BY_ID from '../customerDetail/customerDetailQuery';
import { GET_PRODUCTS } from '../../product/product/productQuery';
import Loading from '../../shared/Loading';

const AddOrder = (props) => {
  const { id } = useParams();

  const customerData = useQuery(GET_CUSTOMER_BY_ID, {
    variables: { id }
  });
  const [addOrder, addedOrderData] = useMutation(ADD_ORDER);
  // Snackbar Here
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };
  const snackbar = (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      style={{ marginTop: 50, width: 500 }}
      // message={snackbarMessage}
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          x
        </IconButton>
      }
    >
      <Alert severity="error" onClose={handleClose}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
  // Snackbar ends
  const initialProduct = {
    productID: '',
    quantity: ''
  };
  const productData = useQuery(GET_PRODUCTS);

  const [items, setItems] = useState([]);
  const initialState = {
    status: 'ACTIVE',
    addressID: '1',
    deliveryDate: '',
    comment: ''
  };

  const [values, setValues] = useState(initialState);
  const [item, setItem] = useState(initialProduct);
  const handleChangeProduct = (event) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleAddProduct = () => {
    if (item.productID && item.quantity) {
      setItems((current) => [...current, item]);
      setItem(initialProduct);
    }
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (items.length > 0) {
      addOrder({
        variables: {
          customerID: id,
          routeID: customerData.data.customer.location.routeID,
          items,
          deliveryDate: new Date(values.deliveryDate),
          addressID: values.addressID,
          status: values.status,
          comment: values.comment
        }
      });
      setValues({ ...initialState });
      setItems([]);
    } else {
      setSnackbarMessage('Please add some product before creating Order.');
      setOpen(true);
    }
  };

  if (productData.loading) return <Loading />;
  if (productData.error) return `${productData.error}`;
  if (customerData.loading) return <Loading />;
  if (customerData.error) return `${customerData.error}`;
  if (addedOrderData.loading) return <Loading />;
  if (addedOrderData.error) return `${addedOrderData.error}`;

  const totalProductData = productData.data.products;
  function addedProductList() {
    return items.map((itemAdded) => {
      const requiredProduct = totalProductData.find(
        (foundProduct) => foundProduct.id === itemAdded.productID
      );
      const productListItem = `Product: ${requiredProduct.name}, Quantity: ${itemAdded.quantity}`;
      return <ListItem key={itemAdded.productID}>{productListItem}</ListItem>;
    });
  }

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="All fields are required."
          title="Add New Order"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography variant="h3">
                {`${customerData.data.customer.firstName} ${customerData.data.customer.lastName} - ${customerData.data.customer.phone}`}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>{customerData.data.customer.address}</Typography>
              <FormHelperText>Delivery Location*</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Delivery Date*"
                name="deliveryDate"
                onChange={handleChange}
                required
                type="date"
                value={values.deliveryDate}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="status"
                onChange={handleChange}
                required
                value={values.status}
                variant="outlined"
              >
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="PENDING">PENDING</MenuItem>
              </Select>
              <FormHelperText>Status*</FormHelperText>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Any extra information, if required."
                label="Comment"
                name="comment"
                onChange={handleChange}
                value={values.comment}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <List>{addedProductList()}</List>
            </Grid>
            <Grid item lg={5} md={6} xs={12}>
              <Select
                fullWidth
                name="productID"
                onChange={handleChangeProduct}
                value={item.productID}
                variant="outlined"
              >
                {totalProductData.map((product) => (
                  <MenuItem value={product.id} key={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Product*</FormHelperText>
            </Grid>
            <Grid item lg={5} md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Quantity of product to be delivered."
                label="Quantity"
                name="quantity"
                onChange={handleChangeProduct}
                type="number"
                value={item.quantity}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddProduct}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Order
          </Button>
        </Box>
      </Card>
      {snackbar}
    </form>
  );
};

export default AddOrder;
