import { gql } from '@apollo/client';

const ADD_TRANSACTION = gql`
  mutation addTransaction(
    $subTotal: Int!
    $customerID: String!
    $date: DateTime!
    $isDebit: Boolean!
    $comment: String
    $orderID: String
    $orgID: String!
  ) {
    addTransaction(
      subTotal: $subTotal
      customerID: $customerID
      date: $date
      isDebit: $isDebit
      comment: $comment
      orderID: $orderID
      orgID: $orgID
    ) {
      id
      orderID
      subTotal
      isDebit
      date
      comment
      customer {
        firstName
        lastName
        phone
      }
    }
  }
`;

export default ADD_TRANSACTION;
