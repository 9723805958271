import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { toast } from 'react-toastify';
import EDIT_CUSTOMER from './customerEditMutation';
import GET_CUSTOMER_BY_ID from '../customerDetail/customerDetailQuery';
import GET_REGIONS from '../../location/region/regionQuery';
import Loading from '../../shared/Loading';

const EditCustomer = (props) => {
  const [editCustomer, edittedCustomerData] = useMutation(EDIT_CUSTOMER);
  const {
    loading: regionLoading,
    error: regionError,
    data: regionData
  } = useQuery(GET_REGIONS);
  const { id } = useParams();
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData
  } = useQuery(GET_CUSTOMER_BY_ID, {
    variables: { id }
  });
  const initialState = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    locationID: '',
    regionID: '',
    address: ''
  };
  const [values, setValues] = useState(initialState);

  useEffect(() => {
    if (!customerLoading && !customerError) {
      const {
        // eslint-disable-next-line object-curly-newline
        customer: {
          firstName,
          lastName,
          phone,
          email,
          locationID,
          location,
          address
        }
      } = customerData;

      setValues({
        firstName,
        lastName,
        phone,
        email,
        address,
        locationID,
        regionID: location.region.id
      });
    }
  }, [customerLoading, customerError, customerData]);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    editCustomer({
      variables: {
        id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        address: values.address,
        phone: values.phone,
        locationID: values.locationID
      }
    });
    toast.success('Customer Updated Successfully!!');
  };
  if (customerLoading) return <Loading />;
  if (customerError) return `${customerData.error}`;
  if (regionLoading) return <Loading />;
  if (regionError) return `${regionData.error}`;
  if (edittedCustomerData.loading) return <Loading />;
  if (edittedCustomerData.error) return `${edittedCustomerData.error}`;
  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="Don't push any null values."
          title="Edit Customer"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="This will be the Customer's login Username."
                label="Mobile Number"
                name="phone"
                onChange={handleChange}
                type="number"
                required
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="This is optional but recommended for future features."
                label="Email"
                name="email"
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="regionID"
                onChange={handleChange}
                required
                value={values.regionID}
                variant="outlined"
              >
                {regionData.regions.map((region) => (
                  <MenuItem value={region.id} key={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Change Region*</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="locationID"
                onChange={handleChange}
                required
                value={values.locationID}
                variant="outlined"
              >
                {regionData &&
                  values.regionID &&
                  regionData.regions
                    .find((region) => region.id === values.regionID)
                    .locations.map((location) => (
                      <MenuItem value={location.id} key={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
              </Select>
              <FormHelperText>Assign Location*</FormHelperText>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                helperText="Complete Delivery Address with Landmark"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Edit Customer
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default EditCustomer;
