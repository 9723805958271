import { gql } from '@apollo/client';

const EDIT_SUBSCRIPTION = gql`
  mutation EditSubscription(
    $id: String!
    $items: [Item]
    $nextDeliveryDate: DateTime
    $endDate: DateTime
    $frequency: Int
    $status: String
  ) {
    editSubscription(
      id: $id
      items: $items
      nextDeliveryDate: $nextDeliveryDate
      endDate: $endDate
      frequency: $frequency
      status: $status
    ) {
      id
      customer {
        firstName
        lastName
        phone
        address
      }
      items
      nextDeliveryDate
      status
      frequency
    }
  }
`;

export default EDIT_SUBSCRIPTION;
