import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import GET_REGIONS from '../region/regionQuery';
import GET_ROUTES from '../../executive/route/routeQuery';
import GET_LOCATIONS from './locationQuery';
import { ADD_LOCATION } from './locationMutation';
import Loading from '../../shared/Loading';

const AddLocation = (props) => {
  const [addLocation, addedLocationData] = useMutation(ADD_LOCATION, {
    // eslint-disable-next-line no-shadow
    update(cache, { data: { addLocation } }) {
      const existingLocations = cache.readQuery({ query: GET_LOCATIONS });
      cache.writeQuery({
        query: GET_LOCATIONS,
        data: {
          locations: [addLocation, ...existingLocations.locations]
        }
      });
    }
  });
  const { loading, error, data } = useQuery(GET_REGIONS);
  const routesData = useQuery(GET_ROUTES);
  const initialValues = {
    locationName: '',
    regionId: '',
    routeId: ''
  };
  const [values, setValues] = useState(initialValues);
  const handleSubmit = (event) => {
    event.preventDefault();
    addLocation({
      variables: {
        name: values.locationName,
        regionID: values.regionId,
        routeID: values.routeId
      }
    });
    setValues({ ...initialValues });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  if (loading) return <Loading />;
  if (error) return 'Error!';
  if (routesData.loading) return <Loading />;
  if (routesData.error) return 'Error!';
  if (addedLocationData.loading) return <Loading />;
  if (addedLocationData.error) return <p>Error...</p>;
  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="Every Location must be assigned to exactly one Region & Route."
          title="Add New Location"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Also select the Region you want to map with this Location."
                label="Location Name"
                name="locationName"
                onChange={handleChange}
                required
                value={values.locationName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="regionId"
                onChange={handleChange}
                required
                value={values.regionId}
                variant="outlined"
              >
                {data.regions.map((region) => (
                  <MenuItem value={region.id} key={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Region*</FormHelperText>
            </Grid>

            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="routeId"
                onChange={handleChange}
                required
                value={values.routeId}
                variant="outlined"
              >
                {routesData.data.routes.map((route) => (
                  <MenuItem value={route.id} key={route.id}>
                    {route.routeName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Route*</FormHelperText>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Location
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AddLocation;
