/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import api from 'src/services/api';
import {
  Snackbar,
  Button,
  IconButton,
  Box,
  Container,
  FormHelperText,
  TextField,
  Typography,
  Alert
} from '@mui/material';
import { kRegisterUrl } from '../utils/apiConstants';

const Register = () => {
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Failed.');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };

  const snackbar = (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      style={{ marginTop: 50, width: 500 }}
      // message={snackbarMessage}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          x
        </IconButton>
      }
    >
      <Alert severity="success" onClose={handleClose}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  return (
    <>
      <Helmet>
        <title>Register | Milkton</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: '',
              firstName: '',
              lastName: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required('Username is required'),
              firstName: Yup.string()
                .max(255)
                .required('First name is required'),
              lastName: Yup.string().max(255).required('Last name is required'),
              password: Yup.string().max(255).required('password is required')
            })}
            onSubmit={(values, { resetForm }) => {
              api({
                method: 'post',
                data: values,
                withcredentials: true,
                url: kRegisterUrl
              }).then((res) => {
                resetForm({ values: '' });
                setSnackbarMessage(res.data);
                setOpen(true);
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box alignItems="center" display="flex" flexDirection="column">
                  <img
                    alt="Gepton Logo"
                    src="https://github.com/GEPTON-INFOTECH/GEPTON-INFOTECH/raw/main/branding/gepton%20-128px.png"
                  />
                </Box>
                <Box
                  sx={{ mb: 3, mt: 1 }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography color="textPrimary" variant="h2">
                    Add Admin
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    New admin will have same administrative privilage as you.
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="First name"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Last name"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>{errors.policy}</FormHelperText>
                )}
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Add Admin
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          {snackbar}
        </Container>
      </Box>
    </>
  );
};

export default Register;
