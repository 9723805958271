import { Helmet } from 'react-helmet';

import DisplaySubscription from '../components/subscription/subscription/DisplaySubscription';

const Subscription = () => (
  <>
    <Helmet>
      <title>Subscriptions | Milkton</title>
    </Helmet>
    <DisplaySubscription />
  </>
);

export default Subscription;
