import { gql } from '@apollo/client';

const GET_SUBSCRIPTIONS = gql`
  {
    subscriptions {
      id
      customer {
        firstName
        lastName
        phone
        address
      }
      items
      nextDeliveryDate
      endDate
      status
      frequency
    }
  }
`;

export default GET_SUBSCRIPTIONS;
