import { gql } from '@apollo/client';

const GET_EXECUTIVE_BY_ID = gql`
  query GetExecutiveById($id: String!) {
    executive(id: $id) {
      id
      firstName
      lastName
      phone
      isActive
      routeID
    }
  }
`;

const GET_EXECUTIVE_ORDERS_BY_ID_AND_DATE = gql`
  query GetExecutiveOrdersByIdAndDate($executiveID: String, $date: Date) {
    ordersByExecutiveIDAndDate(executiveID: $executiveID, date: $date) {
      id
      isSub
      items
      status
      initialQuantity
      deliveryDate
      customer {
        id
        firstName
        lastName
        phone
        address
      }
      comment
    }
  }
`;

export { GET_EXECUTIVE_BY_ID, GET_EXECUTIVE_ORDERS_BY_ID_AND_DATE };
