import axios from 'axios';
import { getAuth } from 'firebase/auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL
});

api.interceptors.request.use(async (config) => {
  const auth = getAuth();
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${auth.currentUser.accessToken}`;
  return config;
});

export default api;
