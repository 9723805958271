import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { OrderSheetExport } from 'src/components';
import CustomerSheetExport from 'src/components/exports/customerSheet';

export default function Export() {
  return (
    <>
      <Helmet>
        <title>Exports | Milkton</title>
      </Helmet>
      <OrderSheetExport />
      <Box
        sx={{
          mt: 5
        }}
      >
        <CustomerSheetExport />
      </Box>
    </>
  );
}
