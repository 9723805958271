import {
  useEffect,
  useState
} from 'react';

import {
  useMutation,
  useQuery
} from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import Loading from '../shared/Loading';
import GET_CUSTOMER_BY_PHONE from './customerQuery';
import ADD_TRANSACTION from './transactionMutation';

const AddTransaction = (props) => {
  const [addTransaction, addedTransactionData] = useMutation(ADD_TRANSACTION);
  const [mobile, setMobile] = useState('');
  const [customerMobile, setCustomerMobile] = useState('');
  const [customerID, setCustomerID] = useState('');

  const customerData = useQuery(GET_CUSTOMER_BY_PHONE, {
    variables: { phone: customerMobile }
  });
  const initialState = {
    subTotal: '',
    isDebit: false,
    comment: ''
  };

  const [values, setValues] = useState(initialState);

  const fetchCustomerDetailsViaPhone = () => {
    setCustomerMobile(mobile);
  };

  useEffect(() => {
    if (customerData.data && customerData.data.customerByPhone == null) {
      setCustomerID('');
    } else if (customerData.data && customerData.data.customerByPhone) {
      setCustomerID(customerData.data.customerByPhone.id);
    } else {
      setCustomerID('');
    }
  }, [customerData]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    addTransaction({
      variables: {
        customerID,
        date: new Date(),
        isDebit: values.isDebit,
        subTotal: parseInt(values.subTotal, 10),
        comment: values.comment,
        orgID: 'shreesurbhi'
      }
    });
    setValues({ ...initialState });
    setCustomerMobile('');
    setMobile('');
    setCustomerID('');
  };

  if (customerData.loading) return <Loading />;
  if (customerData.error) return `${customerData.error}`;
  if (addedTransactionData.loading) return <Loading />;
  if (addedTransactionData.error) return `${addedTransactionData.error}`;

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="All fields are required."
          title="Add New Transaction"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Enter the mobile number you want to recharge"
                required
                label="Mobile Number"
                name="mobile"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
                variant="outlined"
              />
              {customerID !== '' && customerID !== null ? (
                <Typography>
                  {`${customerData.data.customerByPhone.firstName} ${customerData.data.customerByPhone.lastName}`}
                </Typography>
              ) : (
                <Typography>Please enter a valid mobile number</Typography>
              )}
              <Button
                color="secondary"
                variant="contained"
                onClick={fetchCustomerDetailsViaPhone}
              >
                Validate
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Total Amount in Whole Numbers Only"
                required
                label="Amount"
                name="subTotal"
                onChange={handleChange}
                value={values.subTotal}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="isDebit"
                onChange={handleChange}
                required
                value={values.isDebit}
                variant="outlined"
              >
                <MenuItem value>DEBIT</MenuItem>
                <MenuItem value={false}>CREDIT</MenuItem>
              </Select>
              <FormHelperText>Type of Transaction*</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Any extra information, if required."
                label="Comment"
                name="comment"
                onChange={handleChange}
                value={values.comment}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!customerID}
          >
            Add New Transaction
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AddTransaction;
