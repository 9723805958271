import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { EDIT_EXECUTIVE } from './executiveDetailMutation';
import { GET_EXECUTIVE_BY_ID } from './executiveDetailQuery';
import GET_ROUTES from '../../route/routeQuery';
import Loading from '../../../shared/Loading';

const EditExecutive = (props) => {
  const { id } = useParams();
  const {
    loading: executiveLoading,
    error: executiveError,
    data: executiveData
  } = useQuery(GET_EXECUTIVE_BY_ID, {
    variables: { id }
  });
  const {
    loading: routesLoading,
    error: routesError,
    data: routesData
  } = useQuery(GET_ROUTES);

  const [editExecutive, editedExecutiveData] = useMutation(EDIT_EXECUTIVE);

  const initialState = {
    firstName: '',
    lastName: '',
    password: '',
    phone: '',
    isActive: true,
    routeID: ''
  };
  const [values, setValues] = useState(initialState);

  useEffect(() => {
    if (!executiveLoading && !executiveError) {
      const {
        // eslint-disable-next-line object-curly-newline
        executive: { firstName, lastName, password, phone, isActive, routeID }
      } = executiveData;

      setValues({
        firstName,
        lastName,
        password,
        phone,
        isActive,
        routeID
      });
    }
  }, [executiveLoading, executiveError, executiveData]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    editExecutive({
      variables: {
        id,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        phone: values.phone,
        isActive: values.isActive,
        routeID: values.routeID
      }
    });
  };
  if (routesLoading) return <Loading />;
  if (routesError) return 'Error!';
  if (executiveLoading) return <Loading />;
  if (executiveError) return `${executiveError}`;
  if (editedExecutiveData.loading) return <Loading />;
  if (editedExecutiveData.error) return `${editedExecutiveData.error}`;

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="Don't update any empty data."
          title="Edit Executive Data"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="This will be the executive's login Username."
                label="Mobile Number"
                name="phone"
                onChange={handleChange}
                type="number"
                required
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                name="password"
                onChange={handleChange}
                required
                value={values.password}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="routeID"
                onChange={handleChange}
                required
                value={values.routeID}
                variant="outlined"
              >
                {routesData.routes.map((route) => (
                  <MenuItem value={route.id} key={route.id}>
                    {route.routeName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Assign Route*</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="isActive"
                onChange={handleChange}
                required
                value={values.isActive}
                variant="outlined"
              >
                <MenuItem value>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
              <FormHelperText>Active Status*</FormHelperText>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            UPDATE DETAILS
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default EditExecutive;
