import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { ADD_EXECUTIVE } from './executiveMutation';
import GET_EXECUTIVES from './executiveQuery';
import GET_ROUTES from '../route/routeQuery';
import Loading from '../../shared/Loading';

const AddExecutive = (props) => {
  const [addExecutive, addedExecutiveData] = useMutation(ADD_EXECUTIVE, {
    // eslint-disable-next-line no-shadow
    update(cache, { data: { addExecutive } }) {
      const existingExecutives = cache.readQuery({ query: GET_EXECUTIVES });
      cache.writeQuery({
        query: GET_EXECUTIVES,
        data: {
          executives: [addExecutive, ...existingExecutives.executives]
        }
      });
    }
  });
  const { loading, error, data } = useQuery(GET_ROUTES);
  const initialState = {
    firstName: '',
    lastName: '',
    password: '',
    phone: '',
    isActive: true,
    routeID: ''
  };
  const [values, setValues] = useState(initialState);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    addExecutive({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        phone: values.phone,
        isActive: values.isActive,
        routeID: values.routeID
      }
    });
    setValues({ ...initialState });
  };
  if (loading) return <Loading />;
  if (error) return 'Error!';
  if (addedExecutiveData.loading) return <Loading />;
  if (addedExecutiveData.error) return <p>Error...</p>;

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="All fields are required."
          title="Add New Executive"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="This will be the executive's login Username."
                label="Mobile Number"
                name="phone"
                onChange={handleChange}
                type="number"
                required
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                name="password"
                onChange={handleChange}
                required
                value={values.password}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="routeID"
                onChange={handleChange}
                required
                value={values.routeID}
                variant="outlined"
              >
                {data.routes.map((route) => (
                  <MenuItem value={route.id} key={route.id}>
                    {route.routeName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Assign Route*</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="isActive"
                onChange={handleChange}
                required
                value={values.isActive}
                variant="outlined"
              >
                <MenuItem value>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
              <FormHelperText>Active Status*</FormHelperText>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Executive
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AddExecutive;
