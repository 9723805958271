import { NavLink as RouterLink, useParams } from 'react-router-dom';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid';
import { Card, CardHeader, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery, useMutation } from '@apollo/client';
import GET_SUBSCRIPTIONS_BY_CUSTOMER_ID from './subscriptionQuery';
import { DELETE_SUBSCRIPTION } from './subscriptionMutation';
import { GET_PRODUCTS } from '../../product/product/productQuery';
import Loading from '../../shared/Loading';

function ExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function DisplaySubscription() {
  const { id: customerID } = useParams();
  const { loading, error, data } = useQuery(GET_SUBSCRIPTIONS_BY_CUSTOMER_ID, {
    variables: {
      customerID
    }
  });
  // eslint-disable-next-line operator-linebreak
  const [deleteSubscription, deletedSubscriptionData] =
    useMutation(DELETE_SUBSCRIPTION);
  const products = useQuery(GET_PRODUCTS);
  if (loading) return <Loading />;
  if (error) return `${error}`;
  if (products.loading) return <Loading />;
  if (products.error) return `${products.error}`;
  if (deletedSubscriptionData.loading) return <Loading />;
  if (deletedSubscriptionData.error) return `${deletedSubscriptionData.error}`;
  const productData = products.data.products;

  const columns = [
    {
      field: 'name',
      headerName: 'Customer Name',
      width: 180,
      valueGetter: (params) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        `${params.row.customer.firstName} ${params.row.customer.lastName}`
    },
    {
      field: 'phone',
      headerName: 'Mobile Number',
      width: 200,
      valueGetter: (params) => params.row.customer.phone
    },
    {
      field: 'items',
      headerName: 'Items',
      width: 250,
      valueGetter: (params) => {
        let items = '';
        if (params.row.items[0] != null) {
          params.row.items.forEach((item) => {
            const requiredProduct = productData.find(
              (product) => product.id === item.productID
            );
            items += `${requiredProduct.name}(${item.quantity}), `;
          });
          return items.slice(0, -2);
        }
        return 'No Product Found';
      }
    },
    {
      field: 'nextDate',
      headerName: 'Next Delivery',
      width: 180,
      valueGetter: (params) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        new Date(params.row.nextDeliveryDate).toDateString()
    },
    {
      field: 'endDate',
      headerName: 'Ends on',
      width: 180,
      // eslint-disable-next-line no-confusing-arrow
      valueGetter: (params) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        params.row.endDate
          ? new Date(params.row.endDate).toDateString()
          : 'Never ends'
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      width: 120
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 300,
      valueGetter: (params) => params.row.customer.address
    },
    {
      field: 'view',
      headerName: 'More',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        const { id: orderID } = params;
        const path = `/app/subscriptions/${orderID}`;
        return (
          <strong>
            <Button
              component={RouterLink}
              variant="contained"
              color="primary"
              size="small"
              to={path}
              startIcon={<EditIcon />}
            >
              EDIT
            </Button>
          </strong>
        );
      }
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              deleteSubscription({ variables: { id: params.id } });
              // eslint-disable-next-line no-alert
              alert('Deleted, Refresh Page to see effect');
            }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </strong>
      )
    }
  ];
  const rows = data.subscriptionsByCustomerID;

  return (
    <Card>
      <CardHeader
        subheader="Currently added Subscriptions."
        title="Subscriptions"
      />
      <div style={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={25}
          autoHeight
          components={{
            Toolbar: ExportButton
          }}
        />
      </div>
    </Card>
  );
}
