import { gql } from '@apollo/client';

const GET_EXECUTIVES = gql`
  {
    executives {
      id
      firstName
      lastName
      phone
      isActive
      route {
        routeName
      }
    }
  }
`;

export default GET_EXECUTIVES;
