import './styles.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card
} from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = () => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate('/app/dashboard', { replace: true });
      })
      .catch((error) => {
        toast.error(error.code);
      });
  };
  return (
    <>
      <Helmet>
        <title>Login | Milkton</title>
      </Helmet>

      <Card className="page-container">
        <Box
          sx={{
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <Container maxWidth="sm">
            <Box alignItems="center" display="flex" flexDirection="column">
              <img
                alt="Gepton Logo"
                src="https://github.com/GEPTON-INFOTECH/GEPTON-INFOTECH/raw/main/branding/gepton%20-128px.png"
              />
            </Box>
            <Box
              sx={{ mb: 3, mt: 1 }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography color="textPrimary" variant="h2">
                Milkton
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Platform for Shree Surbhi Jadkhor Godham
              </Typography>
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 2 }
              }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              autoComplete="off"
            >
              <TextField
                id="email"
                fullWidth
                required
                label="Enter the Email"
                variant="outlined"
                type="email"
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <TextField
                fullWidth
                required
                id="password"
                label="Enter the Password"
                variant="outlined"
                type="password"
                name="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{ mb: 3, mt: 1 }}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <Button variant="contained" onClick={handleSubmit}>
                Login
              </Button>
            </Box>
          </Container>
        </Box>
      </Card>
    </>
  );
};

export default Login;
