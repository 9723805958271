import { NavLink as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardHeader, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreIcon from '@mui/icons-material/More';
import { useQuery, useMutation } from '@apollo/client';
import GET_EXECUTIVES from './executiveQuery';
import { DELETE_EXECUTIVE } from './executiveMutation';
import Loading from '../../shared/Loading';

export default function DisplayExecutive() {
  const { loading, error, data } = useQuery(GET_EXECUTIVES);
  const [deleteExecutive, deletedExecutiveData] = useMutation(DELETE_EXECUTIVE);
  if (loading) return <Loading />;
  if (error) return 'Error!';
  if (deletedExecutiveData.loading) return <Loading />;
  if (deletedExecutiveData.error) return 'Error!';

  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 250 },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 250
    },
    {
      field: 'phone',
      headerName: 'Mobile Number',
      type: 'Number',
      width: 250
    },
    {
      field: 'route',
      headerName: 'Assigned Route',
      width: 250,
      valueGetter: (params) => params.row.route.routeName
    },
    {
      field: 'isActive',
      headerName: 'Active Status',
      sortable: false,
      width: 180
    },
    {
      field: 'view',
      headerName: 'View More',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Button
            component={RouterLink}
            variant="contained"
            color="primary"
            size="small"
            // eslint-disable-next-line react/destructuring-assignment
            to={params.id}
            startIcon={<MoreIcon />}
          >
            VIEW MORE
          </Button>
        </strong>
      )
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              deleteExecutive({ variables: { id: params.id } });
              // eslint-disable-next-line no-alert
              alert('Deleted, Refresh Page to see effect');
            }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </strong>
      )
    }
  ];
  const rows = data.executives;

  return (
    <Card>
      <CardHeader subheader="Currently added Executives." title="Executives" />
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} />
      </div>
    </Card>
  );
}
