import { gql } from '@apollo/client';

const ADD_EXECUTIVE = gql`
  mutation AddExecutive(
    $firstName: String!
    $lastName: String!
    $phone: String!
    $password: String!
    $routeID: String!
    $photoURL: String
    $isActive: Boolean!
  ) {
    addExecutive(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      password: $password
      routeID: $routeID
      photoURL: $photoURL
      isActive: $isActive
    ) {
      id
      firstName
      lastName
      phone
      isActive
      route {
        routeName
      }
    }
  }
`;

const DELETE_EXECUTIVE = gql`
  mutation DELETE_EXECUTIVE($id: ID!) {
    deleteExecutive(id: $id) {
      id
      firstName
      lastName
      phone
      isActive
      route {
        routeName
      }
    }
  }
`;

export { DELETE_EXECUTIVE, ADD_EXECUTIVE };
