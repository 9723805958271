import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';

import DisplayExecutiveOrders from '../components/executive/executive/executiveDetail/DisplayExecutiveOrders';
import EditExecutive from '../components/executive/executive/executiveDetail/EditExecutive';

const ExecutiveDetail = () => (
  <>
    <Helmet>
      <title>Executive Detail | Milkton</title>
    </Helmet>
    <Box p={3}>
      <EditExecutive />
    </Box>
    <Box p={3}>
      <DisplayExecutiveOrders />
    </Box>
  </>
);

export default ExecutiveDetail;
