import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import Logo from './Logo';

const MainNavbar = (props) => (
  <AppBar elevation={0} {...props}>
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton color="inherit" href="https://gepton.com">
        <HelpIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
