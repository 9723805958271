import { gql } from '@apollo/client';

const GET_CUSTOMER_BY_PHONE = gql`
  query CustomerByPhone($phone: String) {
    customerByPhone(phone: $phone) {
      id
      firstName
      lastName
    }
  }
`;

export default GET_CUSTOMER_BY_PHONE;
