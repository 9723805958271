/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-alert */
import { NavLink as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid';
// eslint-disable-next-line object-curly-newline
import { Card, CardHeader, Button, TextField, Grid, Box } from '@mui/material';
import date from 'date-and-time';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery, useMutation } from '@apollo/client';
import GET_ORDERS_BY_DATE_RANGE from './orderQuery';
// import ADD_TRANSACTION from '../../customer/transaction/transactionMutation';
// import { MARK_ORDER } from '../../executive/executive/executiveDetail/executiveDetailMutation';
import { DELETE_ORDER } from './orderMutation';
import { GET_PRODUCTS } from '../../product/product/productQuery';
import Loading from '../../shared/Loading';

const ExportButton = () => (
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>
);

export default function DisplayOrder() {
  const now = new Date();
  const today = date.addHours(now, 9);
  const initialValue = {
    startDate: today.toISOString().split('T')[0],
    endDate: today.toISOString().split('T')[0]
  };
  const [values, setValues] = useState(initialValue);
  const [selectedRow, setSelectedRow] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const { loading, error, data } = useQuery(GET_ORDERS_BY_DATE_RANGE, {
    variables: {
      startDate: values.startDate,
      endDate: values.endDate
    }
  });
  // const [markOrder, markedOrderData] = useMutation(MARK_ORDER);
  // const [addTransaction, addedTransactionData] = useMutation(ADD_TRANSACTION);
  const products = useQuery(GET_PRODUCTS);
  const [deleteOrder, deletedOrderData] = useMutation(DELETE_ORDER);
  if (loading) return <Loading />;
  if (error) return `${error}`;
  if (products.loading) return <Loading />;
  if (products.error) return `${products.error}`;
  if (deletedOrderData.loading) return <Loading />;
  if (deletedOrderData.error) return `${deletedOrderData.error}`;
  // if (markedOrderData.loading) return <Loading />;
  // if (markedOrderData.error) return `${markedOrderData.error}`;
  // if (addedTransactionData.loading) return <Loading />;
  // if (addedTransactionData.error) return `${addedTransactionData.error}`;
  // if (isLoading) return <Loading />;

  const productData = products.data.products;
  // function calculateTotal(items) {
  //   let subTotal = 0;
  //   items.forEach((item) => {
  //     const requiredProduct = productData.find(
  //       (product) => product.id === item.productID
  //     );
  //     subTotal += requiredProduct.price * item.quantity;
  //   });
  //   return subTotal;
  // }

  const columns = [
    {
      field: 'name',
      headerName: 'Customer Name',
      width: 170,
      valueGetter: (params) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        `${params.row.customer.firstName} ${params.row.customer.lastName}`
    },
    {
      field: 'phone',
      headerName: 'Mobile Number',
      width: 170,
      valueGetter: (params) => params.row.customer.phone
    },
    {
      field: 'items',
      headerName: 'Items',
      width: 250,
      valueGetter: (params) => {
        let items = '';
        if (params.row.items[0] != null) {
          params.row.items.forEach((item) => {
            const requiredProduct = productData.find(
              (product) => product.id === item.productID
            );
            items += `${requiredProduct.name}(${item.quantity}), `;
          });
          return items.slice(0, -2);
        }
        return 'No Product Found';
      }
    },
    {
      field: 'nextDate',
      headerName: 'Delivery Date',
      width: 170,
      valueGetter: (params) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        new Date(params.row.deliveryDate).toDateString()
    },
    {
      field: 'isSub',
      headerName: 'Sub?',
      width: 120,
      valueGetter: (params) => {
        if (params.row.isSub) {
          return 'Subscription';
        }
        return 'One Time';
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150
    },
    {
      field: 'initialQuantity',
      headerName: 'Cron Qty',
      width: 80
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 140
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 300,
      valueGetter: (params) => params.row.customer.address
    },
    {
      field: 'view',
      headerName: 'More',
      width: 120,
      sortable: false,
      renderCell: (params) =>
        params.row.status === 'ACTIVE' || params.row.status === 'PENDING' ? (
          <strong>
            <Button
              component={RouterLink}
              variant="contained"
              color="primary"
              size="small"
              // eslint-disable-next-line react/destructuring-assignment
              to={params.id}
              startIcon={<EditIcon />}
            >
              EDIT
            </Button>
          </strong>
        ) : null
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 120,
      renderCell: (params) =>
        params.row.status === 'ACTIVE' || params.row.status === 'PENDING' ? (
          <strong>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                deleteOrder({ variables: { id: params.id } });
                // eslint-disable-next-line no-alert
                alert('Deleted, Refresh Page to see effect');
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </strong>
        ) : null
    }
  ];
  const rows = data.ordersForDateRange;

  return (
    <Card>
      <CardHeader subheader="Currently added Orders." title="Orders" />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <TextField
              fullWidth
              helperText="Start Date*"
              name="startDate"
              onChange={handleChange}
              required
              type="date"
              value={values.startDate}
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <TextField
              fullWidth
              helperText="End Date*"
              name="endDate"
              onChange={handleChange}
              required
              type="date"
              value={values.endDate}
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                setIsLoading(true);
                selectedRow.forEach((selection) => {
                  const requiredRow = rows.find(
                    (foundRow) => foundRow.id === selection
                  );
                  if (requiredRow.status === 'ACTIVE') {
                    markOrder({
                      variables: {
                        id: requiredRow.id,
                        status: 'UNDELIVERED'
                      }
                    });
                  } else {
                    console.log(`Already ${requiredRow.status}`);
                  }
                });
                setIsLoading(false);
                alert('All Undeliver Marked');
              }}
              startIcon={<CancelIcon />}
            >
              BULK UN-DELIVER
            </Button>
          </Box>
        </Grid> */}
        {/* <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              p: 2
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setIsLoading(true);
                selectedRow.forEach((selection) => {
                  const requiredRow = rows.find(
                    (foundRow) => foundRow.id === selection
                  );
                  console.log(requiredRow);
                  if (requiredRow.status === 'ACTIVE') {
                    markOrder({
                      variables: {
                        id: requiredRow.id,
                        status: 'DELIVERED'
                      }
                    });
                    addTransaction({
                      variables: {
                        customerID: requiredRow.customer.id,
                        orderID: requiredRow.id,
                        date: new Date(),
                        isDebit: true,
                        subTotal: +calculateTotal(requiredRow.items),
                        comment: 'Paid for Order'
                      }
                    });
                  } else {
                    console.log(`Already ${requiredRow.status}`);
                  }
                });
                setIsLoading(false);
                alert('All Deliver Marked');
              }}
              startIcon={<LocalShippingIcon />}
            >
              BULK DELIVER
            </Button>
          </Box>
        </Grid> */}
      </Grid>
      <div style={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={25}
          autoHeight
          selectionModel={selectedRow}
          checkboxSelection
          onSelectionModelChange={(newSelection) => {
            setSelectedRow(newSelection);
          }}
          components={{
            Toolbar: ExportButton
          }}
        />
      </div>
    </Card>
  );
}
