import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line object-curly-newline
import { AppBar, Badge, Box, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';
import Logo from './Logo';

const DashboardNavbar = ({ onMobileNavOpen, signOutUser, ...rest }) => {
  const [notifications] = useState([]);

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          sx={{ display: { xs: 'none', xl: 'block' } }}
        >
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          color="inherit"
          sx={{ display: { lg: 'block', xs: 'none' } }}
          onClick={() => {
            signOutUser();
          }}
        >
          <InputIcon />
        </IconButton>

        <IconButton
          color="inherit"
          sx={{ display: { lg: 'none', xs: 'block' } }}
          onClick={() => {
            signOutUser();
          }}
        >
          <InputIcon />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={onMobileNavOpen}
          sx={{ display: { lg: 'none', xs: 'block' } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
