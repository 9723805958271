import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { ADD_ROUTE } from './routeMutation';
import GET_ROUTES from './routeQuery';
import Loading from '../../shared/Loading';

const AddRoute = (props) => {
  const [addRoute, addedRouteData] = useMutation(ADD_ROUTE, {
    // eslint-disable-next-line no-shadow
    update(cache, { data: { addRoute } }) {
      const existingRoutes = cache.readQuery({ query: GET_ROUTES });
      cache.writeQuery({
        query: GET_ROUTES,
        data: {
          routes: [addRoute, ...existingRoutes.routes]
        }
      });
    }
  });
  const initialState = {
    routeName: ''
  };
  const [values, setValues] = useState(initialState);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    addRoute({
      variables: {
        routeName: values.routeName
      }
    });
    setValues({ ...initialState });
  };

  if (addedRouteData.loading) return <Loading />;
  if (addedRouteData.error) return <p>Error...</p>;

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="You can only assign one route per Executive."
          title="Add New Route"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Each routes will be mapped with locations."
                label="Route Name"
                name="routeName"
                onChange={handleChange}
                required
                value={values.routeName}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Route
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AddRoute;
