import { useParams } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import GET_REGIONS from '../../region/regionQuery';
import GET_ROUTES from '../../../executive/route/routeQuery';
import EDIT_LOCATION from './editLocationMutation';
import GET_LOCATION_BY_ID from './editLocationQuery';
import Loading from '../../../shared/Loading';

const EditLocation = (props) => {
  const { id } = useParams();
  const {
    loading: locationLoading,
    error: locationError,
    data: locationData
  } = useQuery(GET_LOCATION_BY_ID, {
    variables: { id }
  });
  const [editLocation, edittedLocationData] = useMutation(EDIT_LOCATION);
  const { loading, error, data } = useQuery(GET_REGIONS);
  const routesData = useQuery(GET_ROUTES);
  const initialValues = {
    locationName: '',
    regionId: '',
    routeId: ''
  };
  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    if (!locationLoading && !locationError) {
      const {
        // eslint-disable-next-line object-curly-newline
        location: { name, routeID, regionID }
      } = locationData;

      setValues({
        locationName: name,
        regionId: regionID,
        routeId: routeID
      });
    }
  }, [locationLoading, locationError, locationData]);
  const handleSubmit = (event) => {
    event.preventDefault();
    editLocation({
      variables: {
        id,
        name: values.locationName,
        regionID: values.regionId,
        routeID: values.routeId
      }
    });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  if (loading) return <Loading />;
  if (error) return 'Error!';
  if (routesData.loading) return <Loading />;
  if (routesData.error) return 'Error!';
  if (edittedLocationData.loading) return <Loading />;
  if (edittedLocationData.error) return <p>Error...</p>;
  if (locationLoading) return <Loading />;
  if (locationError) return <p>Error...</p>;
  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="Every Location must be assigned to exactly one Region & Route."
          title="Edit Location"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Also select the Region you want to map with this Location."
                label="Location Name"
                name="locationName"
                onChange={handleChange}
                required
                value={values.locationName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="regionId"
                onChange={handleChange}
                required
                value={values.regionId}
                variant="outlined"
              >
                {data.regions.map((region) => (
                  <MenuItem value={region.id} key={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Region*</FormHelperText>
            </Grid>

            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="routeId"
                onChange={handleChange}
                required
                value={values.routeId}
                variant="outlined"
              >
                {routesData.data.routes.map((route) => (
                  <MenuItem value={route.id} key={route.id}>
                    {route.routeName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Route*</FormHelperText>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Edit Location
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default EditLocation;
