import { gql } from '@apollo/client';

const GET_SUBSCRIPTION = gql`
  query GetSubscription($id: String!) {
    subscription(id: $id) {
      customer {
        firstName
        lastName
        phone
        address
      }
      items
      nextDeliveryDate
      endDate
      frequency
      status
    }
  }
`;

export default GET_SUBSCRIPTION;
