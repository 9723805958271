/* eslint-disable operator-linebreak */
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Avatar,
  Box,
  Typography,
  Grid,
  Button
} from '@mui/material';
import { useQuery } from '@apollo/client';

import GET_CUSTOMER_BY_ID from './customerDetailQuery';
import Loading from '../../shared/Loading';
import textLocalSingleSms from '../../../services/sms/textlocal';

export default function CustomerCard() {
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_CUSTOMER_BY_ID, {
    variables: { id }
  });

  if (loading) return <Loading />;
  if (error) return `${error}`;
  const name = `Name: ${data.customer.firstName} ${data.customer.lastName}`;
  const phone = `Mobile: ${data.customer.phone}`;
  const wallet = `Wallet Balance: ₹ ${data.customer.wallet}`;
  const now = new Date();
  const mm = `0${now.getMonth()}`.slice(-2);
  // eslint-disable-next-line operator-linebreak
  const path =
    mm !== '00'
      ? `/bill/${id}/${mm}/${now.getUTCFullYear()}`
      : `/bill/${id}/12/${+now.getUTCFullYear() - 1}`;
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDay = new Date(now.getFullYear(), now.getMonth(), 0);
  const month =
    `0${new Date().getMonth()}`.slice(-2) !== '00'
      ? `0${new Date().getMonth()}`.slice(-2)
      : '12';
  const billMonth = `b${month}`;
  const sendBill = () => {
    const message = `Dear Customer, Your amount due as of ${lastDay.getDate()}-${month}-${lastDay.getFullYear()} is (Rs. ${
      data.customer[billMonth]
    } ). The statement for your account of the duration from ${firstDay.getDate()}-${month}-${firstDay.getFullYear()} to ${lastDay.getDate()}-${month}-${lastDay.getFullYear()} is https://shreesurbhi.gepton.in/bill/${id}/${month}/${lastDay.getFullYear()} Kindly click on the link above to pay your outstanding amount - SHREE KRISHNA BALRAM GRAMODHYOG NYAS`;
    textLocalSingleSms(`91${data.customer.phone}`, message);
  };

  return (
    <Card>
      <CardHeader title="Delivery Address" subheader={data.customer.address} />
      <Divider />
      <CardContent>
        <Card>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box>
              <CardHeader title={name} subheader={phone} />
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                component={RouterLink}
                to="edit"
              >
                EDIT CUSTOMER
              </Button>
              &emsp;
              <Button
                color="secondary"
                variant="contained"
                component={RouterLink}
                to={path}
              >
                GENERATE BILL
              </Button>
              &emsp;
              <Button variant="contained" onClick={sendBill}>
                SEND BILL
              </Button>
              &emsp;
            </Box>
          </Box>
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={4} md={6} sm={12}>
                <Typography variant="h5">{wallet}</Typography>
                <Typography variant="h5">
                  Assigned Hub:
                  {data.customer.location.region.hub.hubName}
                </Typography>
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <Typography variant="h5">
                  Region:
                  {data.customer.location.region.name}
                </Typography>
                <Typography variant="h5">
                  Location:
                  {data.customer.location.name}
                </Typography>
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <Typography variant="h5">
                  Route:
                  {data.customer.location.route.routeName}
                </Typography>
                <Typography variant="h5">
                  Email:
                  {data.customer.email}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box p={3} />
        <Card>
          <CardHeader
            title="Assigned Executive"
            subheader="First one incase of multiple assigned."
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={4} md={6} sm={12}>
                <Avatar
                  src={data.customer.location.route.executive[0].photoURL}
                  sx={{
                    height: 100,
                    width: 100
                  }}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <Typography>Name:</Typography>
                <Typography variant="h3">
                  {`${data.customer.location.route.executive[0].firstName} ${data.customer.location.route.executive[0].lastName}`}
                </Typography>
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <Typography>Phone:</Typography>
                <Typography color="textPrimary" gutterBottom variant="h3">
                  {data.customer.location.route.executive[0].phone}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}
