import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';

import EditSubsctiption from '../components/subscription/subscriptionEdit/EditSubscription';

const ExecutiveDetail = () => (
  <>
    <Helmet>
      <title>Edit Subscription | Milkton</title>
    </Helmet>
    <Box p={3}>
      <EditSubsctiption />
    </Box>
  </>
);

export default ExecutiveDetail;
