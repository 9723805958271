import { gql } from '@apollo/client';

const GET_CUSTOMER_BY_ID = gql`
  query GetCustomerById($id: ID!) {
    customer(id: $id) {
      firstName
      lastName
      phone
      email
      b01
      b02
      b03
      b04
      b05
      b06
      b07
      b08
      b09
      b10
      b11
      b12
      wallet
      locationID
      address
      location {
        routeID
        name
        route {
          routeName
          executive {
            firstName
            lastName
            phone
            photoURL
          }
        }
        region {
          id
          name
          hub {
            hubName
          }
        }
      }
    }
  }
`;

export default GET_CUSTOMER_BY_ID;
