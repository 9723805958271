import { gql } from '@apollo/client';

const GET_CREDIT_TRANSACTION_DATE = gql`
  query CreditTransactionsByDate($startDate: Date, $endDate: Date) {
    creditTransactionsByDate(startDate: $startDate, endDate: $endDate) {
      id
      orderID
      subTotal
      isDebit
      date
      comment
      customer {
        firstName
        lastName
        phone
      }
    }
  }
`;

export default GET_CREDIT_TRANSACTION_DATE;
