import { gql } from '@apollo/client';

const GET_ORDERS_BY_DATE_RANGE = gql`
  query OrderForDateRange($startDate: Date, $endDate: Date) {
    ordersForDateRange(startDate: $startDate, endDate: $endDate) {
      id
      isSub
      items
      deliveryDate
      status
      initialQuantity
      comment
      customer {
        id
        firstName
        lastName
        phone
        address
      }
    }
  }
`;

export default GET_ORDERS_BY_DATE_RANGE;
