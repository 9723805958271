import { gql } from '@apollo/client';

const GET_PRODUCTS = gql`
  {
    products {
      id
      name
      price
      description
      category {
        name
      }
      hubs {
        hubName
      }
    }
  }
`;

const GET_PRODUCT_BY_ID = gql`
  query GetProductById($id: ID) {
    product(id: $id) {
      name
    }
  }
`;

export { GET_PRODUCTS, GET_PRODUCT_BY_ID };
