import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { toast } from 'react-toastify';
import ADD_CUSTOMER from './customerMutation';
import GET_REGIONS from '../../location/region/regionQuery';
import Loading from '../../shared/Loading';

const AddCustomer = (props) => {
  const [addCustomer, addedCustomerData] = useMutation(ADD_CUSTOMER);
  const { loading, error, data } = useQuery(GET_REGIONS);
  const initialState = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    locations: [],
    locationID: '',
    address: ''
  };
  const [values, setValues] = useState(initialState);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    addCustomer({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        locationID: values.locationID,
        address: values.address
      }
    });
    setValues({ ...initialState });
    toast.success('Customer Added Successfully!!');
  };
  if (loading) return <Loading />;
  if (error) return `${error}`;
  if (addedCustomerData.loading) return <Loading />;
  if (addedCustomerData.error) return `${addedCustomerData.error}`;

  return (
    <form autoComplete="off" {...props} onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="All fields are required."
          title="Add New Customer"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="This will be the Customer's login Username."
                label="Mobile Number"
                name="phone"
                onChange={handleChange}
                type="number"
                required
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="This is optional but recommended for future features."
                label="Email"
                name="email"
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="locations"
                onChange={handleChange}
                required
                value={values.locations}
                variant="outlined"
              >
                {data.regions.map((region) => (
                  <MenuItem value={region.locations} key={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Assign Region*</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                fullWidth
                name="locationID"
                onChange={handleChange}
                required
                value={values.locationID}
                variant="outlined"
              >
                {values.locations.map((location) => (
                  <MenuItem value={location.id} key={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Assign Location*</FormHelperText>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Complete Delivery address for the customer."
                label="Address"
                name="address"
                onChange={handleChange}
                value={values.address}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Add New Customer
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AddCustomer;
